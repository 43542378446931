import { CategoryType } from "../../types";
import { usePageRecord } from "../../components/PageLoader";
import { Button, PageHeader } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { CategoryTable } from "./CategoryTable";
import {
  parseListResponse,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { useSession } from "../../providers/SessionProvider";
import { EditOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

export function CategorySubcategoriesListPage() {
  const { http } = useSession();
  const history = useHistory();

  const {
    record: parentCategory,
    recordId: parentCategoryId,
  } = usePageRecord<CategoryType>();

  const apiRef = useRef<RecordsTableApi<CategoryType> | undefined>(undefined);

  async function fetchSubcategoriesRecords(params: RecordsTableFetchParams) {
    const resp = await http.get(
      `/categories/${parentCategoryId}/child-categories`,
      {
        params: recordsToFetchParams(params),
      }
    );

    return parseListResponse<CategoryType>(resp);
  }

  return (
    <PageHeader
      title="Subcategories"
      onBack={() => history.push("/categories")}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[
            { name: "Categories", to: "/categories" },
            { name: parentCategory.name },
          ]}
        />
      )}
      extra={[
        <Button
          type="primary"
          onClick={() =>
            history.push(`/categories/create?parentId=${parentCategoryId}`)
          }
        >
          Create
        </Button>,
      ]}
    >
      <CategoryTable
        apiRef={apiRef}
        withLinks={false}
        resource={`categories.${parentCategoryId}`}
        fetchRecords={fetchSubcategoriesRecords}
        parentCategory={parentCategory}
        action={(category) => (
          <Button
            icon={<EditOutlined />}
            onClick={() =>
              history.push({
                pathname: `/categories/${category.id}/edit`,
                search: `?parentId=${parentCategoryId}`,
                state: {
                  category: {
                    ...category,
                    parentCategory,
                  },
                },
              })
            }
          />
        )}
      />
    </PageHeader>
  );
}

import React, { useState } from "react";
import { message, PageHeader } from "antd";
import { RayForm } from "./RayForm";
import { useSession } from "../../providers/SessionProvider";
import { useHistory } from "react-router-dom";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";

export function RayCreatePage() {
  const [loading, setLoading] = useState(false);
  const { http } = useSession();
  const history = useHistory();

  async function handleCreateRay(payload: any) {
    try {
      setLoading(true);
      await http.post("/rays", payload);
      setLoading(false);
      message.success("Ray was successfully created.");
      history.push("/rays");
    } catch (ex) {
      console.error(ex);
      setLoading(false);
      message.error("Couldn't create a ray.");
    }
  }

  return (
    <PageHeader
      title="Create a Ray"
      onBack={() => history.push("/rays")}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[{ name: "Rays", to: "/rays" }, { name: "Create" }]}
        />
      )}
    >
      <RayForm
        onFinish={handleCreateRay}
        loading={loading}
        submitText="Create"
      />
    </PageHeader>
  );
}

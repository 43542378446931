import React from "react";
import {
  parseListResponse,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { RaylistType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { Button, PageHeader, Space } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { RaylistTable } from "./RaylistTable";

export function RaylistListPage() {
  const { http } = useSession();
  const history = useHistory();

  async function fetchRaylistRecords(params: RecordsTableFetchParams) {
    const response = await http.get(`/ray-list`, {
      params: recordsToFetchParams(params),
    });

    return parseListResponse<RaylistType>(response);
  }

  const actions = (raylist: RaylistType) => (
    <Button
      icon={<EditOutlined />}
      type="text"
      onClick={() =>
        history.push(`/raylists/${raylist.id}/update`, { raylist })
      }
    />
  );

  return (
    <PageHeader
      title="Raylists"
      breadcrumbRender={() => (
        <PageBreadcrumbs items={[{ name: "Raylists" }]} />
      )}
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => history.push("/raylists/create")}
          >
            Create
          </Button>
        </Space>
      }
    >
      <RaylistTable
        actions={actions}
        resource="raylists"
        fetchRecords={fetchRaylistRecords}
      />
    </PageHeader>
  );
}

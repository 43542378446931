import React, { useState } from "react";
import { Button, message, PageHeader, Popconfirm } from "antd";
import { RayForm } from "./RayForm";
import { useSession } from "../../providers/SessionProvider";
import { useHistory } from "react-router-dom";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { usePageRecord } from "../../components/PageLoader";
import { RayType } from "../../types";

export function RayUpdatePage() {
  const [loading, setLoading] = useState(false);
  const { http } = useSession();
  const history = useHistory();

  const { record: ray, recordId: rayId } = usePageRecord<RayType>();

  async function handleUpdateRay(payload: any) {
    try {
      setLoading(true);
      await http.put(`/rays/${rayId}`, payload);
      setLoading(false);
      message.success("Ray was successfully updated.");
      history.push("/rays");
    } catch (ex) {
      console.error(ex);
      setLoading(false);
      message.error("Couldn't update a ray.");
    }
  }

  async function handleDeleteRay() {
    if (!ray) {
      return;
    }

    try {
      await http.delete(`/rays/${ray.id}`);
      message.success("Ray was successfully deleted.");
      history.push("/rays");
    } catch (ex) {
      console.error(ex);
      setLoading(false);
      message.error("Couldn't delete a ray.");
    }
  }

  return (
    <PageHeader
      title="Update a Ray"
      onBack={() => history.push("/rays")}
      subTitle={rayId}
      extra={
        <Popconfirm
          title="Do you really want to delete a ray?"
          placement="bottomRight"
          okText="Yes, Delete"
          okButtonProps={{ danger: true }}
          onConfirm={handleDeleteRay}
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      }
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[{ name: "Rays", to: "/rays" }, { name: "Update" }]}
        />
      )}
    >
      <RayForm
        onFinish={handleUpdateRay}
        loading={loading}
        submitText="Update"
        ray={ray}
      />
    </PageHeader>
  );
}

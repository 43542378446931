import { usePageRecord } from "../../components/PageLoader";
import { RaylistType, RayType } from "../../types";
import { Button, PageHeader, Popconfirm, message } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { RayTable } from "../ray/RayTable";
import { useSession } from "../../providers/SessionProvider";
import {
  parseListResponse,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { RaylistRayMappingModal } from "./RaylistRayMappingModal";
import { useRef, useState } from "react";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export function RaylistDetailsPage() {
  const { http } = useSession();
  const history = useHistory();
  const { record: raylist, recordId: raylistId } = usePageRecord<RaylistType>();
  const [mappingVisible, setMappingVisible] = useState(false);
  const tableApi = useRef<RecordsTableApi<RayType> | undefined>(undefined);

  const fetchRaysRecords = async (params: RecordsTableFetchParams) => {
    const response = await http.get(`/rays/ray-list/${raylistId}`, {
      params: recordsToFetchParams(params),
    });

    return parseListResponse<RayType>(response);
  };

  function closeModal() {
    setMappingVisible(false);
    tableApi.current?.refresh();
  }

  async function removeRay(ray: RayType) {
    await http.delete(
      `/ray-list-mappings/ray/${ray.id}/ray-list/${raylist.id}`
    );
    message.success("Ray removed");
    tableApi.current?.refresh();
  }

  const actions = (ray: RayType) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          icon={<EditOutlined />}
          type="text"
          onClick={() =>
            history.push({ pathname: `/rays/${ray.id}/update`, state: { ray } })
          }
          style={{ marginRight: 10 }}
        />
        <Popconfirm
          title="Would you like to delete a rey?"
          onConfirm={() => removeRay(ray)}
          okText="Yes, Delete"
          cancelText="No"
          placement="topRight"
          okButtonProps={{ danger: true }}
        >
          <CloseOutlined />
        </Popconfirm>
      </div>
    );
  };

  return (
    <PageHeader
      title="Rays"
      onBack={() => history.push("/raylists")}
      extra={
        <Button type="primary" onClick={() => setMappingVisible(true)}>
          Add a Ray
        </Button>
      }
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[
            { name: "Raylists", to: "/raylists" },
            { name: raylist.caption },
          ]}
        />
      )}
    >
      {mappingVisible && (
        <RaylistRayMappingModal
          raylist={raylist}
          visible={mappingVisible}
          onCancel={closeModal}
        />
      )}

      <RayTable
        apiRef={tableApi}
        actions={actions}
        fetchRecords={fetchRaysRecords}
        resource={`${raylistId}.rays`}
      />
    </PageHeader>
  );
}

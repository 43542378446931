import React, { useState } from "react";
import { Button, Col, message, PageHeader, Popconfirm, Row } from "antd";
import { useHistory } from "react-router-dom";

import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { RaylistForm } from "./RaylistForm";
import { useSession } from "../../providers/SessionProvider";
import { usePageRecord } from "../../components/PageLoader";
import { RaylistType } from "../../types";

export function RaylistUpdatePage() {
  const { http } = useSession();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { record: raylist, recordId: raylistId } = usePageRecord<RaylistType>();

  async function handleUpdateRayList(payload: any) {
    try {
      setLoading(true);
      await http.put(`/ray-list/${raylistId}`, payload);
      message.success("RayList was successfully updated.");
      setLoading(false);
      history.push("/raylists");
    } catch (ex) {
      setLoading(false);
      message.error("Could not update a RayList.");
    }
  }

  async function handleDeleteRay() {
    setDeleteLoading(true);

    try {
      await http.delete(`/ray-list/${raylistId}`);
      setDeleteLoading(false);
      message.success("Raylist was successfully deleted.");
      history.push("/raylists");
    } catch (ex) {
      setDeleteLoading(false);
      message.error("Could not delete raylist.");
    }
  }

  return (
    <PageHeader
      title="Update a RayList"
      onBack={() => history.push("/raylists")}
      extra={[
        <Popconfirm
          title="Do you really want to delete a raylist?"
          placement="bottomRight"
          okText="Yes, Delete"
          okButtonProps={{ danger: true }}
          onConfirm={handleDeleteRay}
        >
          <Button danger loading={deleteLoading}>
            Delete
          </Button>
        </Popconfirm>,
      ]}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[{ name: "Raylists", to: "/raylists" }, { name: "Update" }]}
        />
      )}
    >
      <Row>
        <Col span={12}>
          <RaylistForm onFinish={handleUpdateRayList} raylist={raylist}>
            <Button htmlType="submit" loading={loading} type="primary">
              Update
            </Button>
          </RaylistForm>
        </Col>
      </Row>
    </PageHeader>
  );
}

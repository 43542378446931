import React, { useEffect, useRef, useState } from "react";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/es";
import { UploadFile } from "antd/es/upload/interface";
import _ from "lodash";
import axios from "axios";
import { useSession } from "../../providers/SessionProvider";
import { RcFile } from "antd/lib/upload";

export type UploadFileType =
  | "PERSON_AVATAR"
  | "RAY_BACKGROUND_IMAGE"
  | "RAY_SOCIAL_IMAGE_STORY"
  | "RAY_SOCIAL_IMAGE_SQUARE"
  | "RAY_LIST_IMAGE_CARD"
  | "RAY_LIST_IMAGE_DETAIL"
  | "STORY_IMAGE"
  | "CATEGORY_IMAGE_CARD"
  | "CATEGORY_IMAGE_DETAIL"
  | "CATEGORY_BACKGROUND_VIDEO"
  | "RAY_DEVICE_VIDEO"
  | "RAY_PHONE_IMAGE"
  | "RAY_APP_VIDEO"
  | "SLIDE_2_BG_IMAGE_URL"
  | "ARCHETYPE_BACKGROUND_IMAGE"
  | "ARCHETYPE_BACKGROUND_VIDEO";

export interface UploadS3FileProps<T> {
  value?: string;
  type?: "picture" | "file";
  onChange?(value: string): void;
  fileType: UploadFileType;
  uploadProps?: UploadProps<T>;
}

export function UploadS3File<T>(props: UploadS3FileProps<T>) {
  const { http } = useSession();
  const { value, onChange, fileType } = props;
  const uploadUrlRef = useRef<string>();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (!value || _.isEmpty(value)) {
      return;
    }

    const uid = _.uniqueId("uf_");

    const uploadFile = {
      name: _.last(value.split("/")),
      size: 0,
      status: "done",
      url: value,
      uid,
    } as UploadFile;

    setFileList([uploadFile]);
  }, [value]);

  const url = async (file: RcFile) => {
    const resp = await http.get("/media", {
      headers: {
        "Content-Type": file.type,
      },
      params: {
        fileType,
        fileName: file.name,
      },
      data: null, // We must provide empty data to be able to set content-type https://github.com/axios/axios/issues/86
    });

    // const uploadURL = new URL(resp.data.uploadUrl);
    // uploadURL.searchParams.append("Content-Type", file.type);
    // console.log("upload url: ", uploadURL, uploadURL.toString());
    // uploadUrlRef.current = uploadURL.toString();
    uploadUrlRef.current = resp.data.uploadUrl;
    return uploadUrlRef.current as string;
  };

  const isPicture = props.type === "picture";

  return (
    <div>
      <Upload
        {...props.uploadProps}
        maxCount={1}
        fileList={fileList}
        action={(file) => url(file)}
        listType={isPicture ? "picture-card" : undefined}
        method="put"
        multiple={false}
        onChange={(change) => {
          setFileList(change.fileList);

          if (change.file.status === "done" && uploadUrlRef.current) {
            const fileUrl = new URL(uploadUrlRef.current);
            fileUrl.search = ""; // remove upload search params
            onChange?.(fileUrl.toString());
          }
        }}
        onRemove={() => onChange?.("")}
        customRequest={async (options) => {
          const file = options.file as RcFile;

          try {
            const response = await axios.put(options.action, options.file, {
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress(progress: ProgressEvent) {
                options.onProgress?.({
                  ...progress,
                  percent: 100 * (progress.loaded / progress.total),
                });
              },
            });

            options.onSuccess?.(null, response.request);
          } catch (ex) {
            options.onError?.(ex);
          }
        }}
      >
        {fileList.length === 0 &&
          (isPicture ? (
            <span>
              <UploadOutlined /> Upload
            </span>
          ) : (
            <Button icon={<UploadOutlined />}>Upload</Button>
          ))}
      </Upload>
    </div>
  );
}

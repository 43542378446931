import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

interface UseRecordLoaderProps<RecordType> {
  fetchRecord(id: string): Promise<RecordType>;
  idParam: string;
  recordStateProp: string;
}

export interface UseRecordValue<RecordType> {
  recordId: string;
  record?: RecordType;
  loading: boolean;
  error?: Error;
  loadRecord: () => void;
}

export interface UseExactRecordValue<RecordType> {
  recordId: string;
  record: RecordType;
  loading: boolean;
  error?: Error;
  loadRecord: () => void;
}

export function useRecordLoader<RecordType>(
  props: UseRecordLoaderProps<RecordType>
): UseRecordValue<RecordType> {
  const { fetchRecord, recordStateProp, idParam } = props;
  const location = useLocation<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(undefined);

  const stateRecord = location.state?.[recordStateProp] as
    | RecordType
    | undefined;

  const [record, setRecord] = useState<RecordType | undefined>(stateRecord);
  const params = useParams<any>();
  const recordId = params[idParam];

  if (!recordId) {
    throw new Error(
      "Missing recordId in url param. Please check idParam prop."
    );
  }

  const loadRecord = useCallback(async () => {
    setRecord(stateRecord);
    setError(undefined);
    setLoading(true);

    try {
      const loadedRecord = await fetchRecord(recordId);
      setRecord(loadedRecord);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      setError(ex);
    }
  }, [fetchRecord, recordId, stateRecord]);

  useEffect(() => {
    loadRecord();
  }, [loadRecord]);

  return {
    record,
    recordId,
    loading,
    error,
    loadRecord,
  };
}

import { Col, Form, FormProps, Input, Row } from "antd";
import { UploadS3File } from "../../components/Form/UploadS3File";

export function CategoryForm(props: FormProps) {
  const { children, ...formProps } = props;
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="subtitle" label="Subtitle" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>

      <Row>
        <Col span={12}>
          <Form.Item
            name="imageCardUrl"
            label="Card Image"
            rules={[{ required: true }]}
          >
            <UploadS3File fileType="CATEGORY_IMAGE_CARD" type="picture" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="imageDetailUrl"
            label="Detail Image"
            rules={[{ required: true }]}
          >
            <UploadS3File fileType="CATEGORY_IMAGE_DETAIL" type="picture" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="backgroundVideoUrl" label="Background Video">
        <UploadS3File fileType="CATEGORY_BACKGROUND_VIDEO" type="file" />
      </Form.Item>

      {children}
    </Form>
  );
}

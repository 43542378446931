import { useCallback, useRef } from "react";
import { PageHeader, Tag } from "antd";
import { Link } from "react-router-dom";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import {
  parseListResponse,
  RecordsProvider,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { DeviceStateType, DeviceStatusType, DeviceType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { RecordsTableHeader } from "../../components/RecordsTableHeader";
import { RecordsTable } from "../../components/RecordsTable";
import {
  DeviceFWVersion,
  DeviceOnlineStatus,
  DeviceState,
  DeviceStatusFWVersion,
  DeviceWifiSignal,
} from "./DevicesComponents";

export function DevicesListPage() {
  const { http } = useSession();
  const apiRef = useRef<RecordsTableApi<DeviceType> | undefined>(undefined);

  const columns = [
    {
      dataIndex: "serial",
      title: "Serial No.",
      render: (serial: string, device: DeviceType) => {
        return (
          <Link
            to={{
              pathname: `/devices/${device.id}/details`,
              state: { device },
            }}
          >
            {serial}
          </Link>
        );
      },
    },
    {
      dataIndex: "hwVersion",
      title: "H/W Ver.",
      render: (v: string) => <Tag>{v}</Tag>,
    },
    {
      dataIndex: "firmwareVersionName",
      title: "OTA F/W Ver.",
      render: (v: string, device: DeviceType) => (
        <DeviceFWVersion device={device} />
      ),
      sorter: {},
    },
    {
      dataIndex: "firmwareVersionCode",
      key: "statusFirmwareVersion",
      title: "F/W Ver.",
      render: (v: any, device: DeviceType) => (
        <DeviceStatusFWVersion device={device} />
      ),
      sorter: {},
    },
    {
      dataIndex: "status",
      title: "Online Status",
      render: (status: DeviceStatusType) => {
        return <DeviceOnlineStatus status={status} />;
      },
    },
    {
      dataIndex: "state",
      title: "State",
      render: (state: DeviceStateType) => {
        return <DeviceState state={state} />;
      },
      sorter: {},
    },
    {
      dataIndex: "status",
      title: "Wifi Signal",
      render: (status: DeviceStatusType) => {
        return <DeviceWifiSignal status={status} />;
      },
    },
  ];

  const fetchDevices = useCallback(
    async (params: RecordsTableFetchParams) => {
      const resp = await http.get("/devices", {
        params: {
          withStatus: true,
          ...recordsToFetchParams(params),
        },
      });

      return parseListResponse<DeviceType>(resp);
    },
    [http]
  );

  return (
    <PageHeader
      title="Devices"
      breadcrumbRender={() => <PageBreadcrumbs items={[{ name: "Devices" }]} />}
    >
      <RecordsProvider<DeviceType>
        apiRef={apiRef}
        resource="devices"
        columns={columns}
        fetchRecords={fetchDevices}
        initialPageSize={20}
        autoRefresh={15 * 1000}
      >
        <RecordsTableHeader />
        <RecordsTable size="small" />
      </RecordsProvider>
    </PageHeader>
  );
}

import React from "react";
import { PersonType } from "../../types";
import { Space, Typography } from "antd";
import { PersonAvatar } from "./PersonAvatar";

interface PersonTableCellProps {
  person: PersonType;
}

export function PersonTableCell(props: PersonTableCellProps) {
  const { person } = props;

  return (
    <Space direction="horizontal" size={10}>
      <PersonAvatar person={person} />

      <div>
        <Typography.Text strong>
          {person.firstName} {person.lastName}
        </Typography.Text>
        <br />
        <Typography.Text
          type="secondary"
          ellipsis={{ tooltip: person.birthdate }}
        >
          {person.birthdate || "---"}
        </Typography.Text>
      </div>
    </Space>
  );
}

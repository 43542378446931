import React, { useCallback, useRef, useState } from "react";
import { Button, message, PageHeader, Space } from "antd";
import {
  parseListResponse,
  RecordsTableFetchParams,
  recordsToFetchParams,
  RecordsTableApi,
} from "../../providers/RecordsProvider";
import { useSession } from "../../providers/SessionProvider";
import { PersonType } from "../../types";
import { PersonFormModal } from "./PersonFormModal";
import { EditOutlined } from "@ant-design/icons";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { PersonTable } from "./PersonTable";

export function PersonListPage() {
  const { http } = useSession();
  const apiRef = useRef<RecordsTableApi<PersonType>>();

  const [[modalVisible, modalType, modalValues], setCreateModalVisible] =
    useState<[boolean, string, PersonType | undefined]>([
      false,
      "create",
      undefined,
    ]);

  const fetchPersonList = useCallback(
    async (params: RecordsTableFetchParams) => {
      const response = await http.get("/persons", {
        params: recordsToFetchParams(params),
      });

      return parseListResponse<PersonType>(response);
    },
    [http]
  );

  function hideModal() {
    setCreateModalVisible([false, "create", undefined]);
  }

  function showCreateModal() {
    setCreateModalVisible([true, "create", undefined]);
  }

  function showEditModal(person: PersonType) {
    setCreateModalVisible([true, "edit", person]);
  }

  async function handleCreatePerson(person: PersonType) {
    await http.post("/persons", person);
    message.success("Person was successfully created");
    apiRef.current?.refresh();
    hideModal();
  }

  async function handleUpdatePerson(payload: PersonType, person: PersonType) {
    await http.put(`/persons/${person.id}`, payload);
    message.success("Person was successfully updated");
    apiRef.current?.refresh();
    hideModal();
  }

  async function handleDeletePerson(person: PersonType) {
    await http.delete(`/persons/${person.id}`);
    message.success("Person was successfully deleted");
    apiRef.current?.refresh();
    hideModal();
  }

  const actions = (person: PersonType) => (
    <Button
      icon={<EditOutlined />}
      type="text"
      onClick={() => showEditModal(person)}
    />
  );

  return (
    <>
      <PersonFormModal
        key={modalValues ? modalValues.id : "create-modal"}
        visible={modalVisible}
        title={modalType === "create" ? "Create a person" : "Update a person"}
        okText={modalType === "create" ? "Create" : "Update"}
        onDelete={modalType === "create" ? undefined : handleDeletePerson}
        initialValues={modalValues}
        onCancel={() => hideModal()}
        onSubmit={
          modalType === "create" ? handleCreatePerson : handleUpdatePerson
        }
      />

      <PageHeader
        title="Persons"
        breadcrumbRender={() => (
          <PageBreadcrumbs items={[{ name: "Persons" }]} />
        )}
        extra={
          <Space size={8} direction="horizontal">
            <Button type="primary" onClick={() => showCreateModal()}>
              Create
            </Button>
          </Space>
        }
      >
        <PersonTable
          apiRef={apiRef}
          resource="persons"
          fetchRecords={fetchPersonList}
          actions={actions}
        />
      </PageHeader>
    </>
  );
}

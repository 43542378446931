import React from "react";
import { Button, PageHeader, Space } from "antd";
import { useSession } from "../../providers/SessionProvider";
import {
  parseListResponse,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { RayType } from "../../types";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { useHistory } from "react-router-dom";
import { RayTable } from "./RayTable";
import { EditOutlined } from "@ant-design/icons";

export function RayListPage() {
  const { http } = useSession();
  const history = useHistory();

  async function fetchRaysRecords(params: RecordsTableFetchParams) {
    const response = await http.get("/rays", {
      params: recordsToFetchParams(params),
    });

    return parseListResponse<RayType>(response);
  }

  const actions = (ray: RayType) => {
    return (
      <Button
        icon={<EditOutlined />}
        type="text"
        onClick={() =>
          history.push({ pathname: `/rays/${ray.id}/update`, state: { ray } })
        }
      />
    );
  };

  return (
    <PageHeader
      title="Rays"
      breadcrumbRender={() => <PageBreadcrumbs items={[{ name: "Rays" }]} />}
      extra={
        <Space>
          <Button type="primary" onClick={() => history.push("/rays/create")}>
            Create
          </Button>
        </Space>
      }
    >
      <RayTable
        resource="rays"
        fetchRecords={fetchRaysRecords}
        actions={actions}
      />
    </PageHeader>
  );
}

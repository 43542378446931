import { Button, Dropdown, Menu, message, PageHeader, Tag } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import {
  parseListResponse,
  RecordsProvider,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import {
  PersonType,
  UserSocialType,
  UserStatusType,
  UserType,
} from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { RecordsTable } from "../../components/RecordsTable";
import { RecordsTableHeader } from "../../components/RecordsTableHeader";
import { PersonTableCell } from "../person/PersonTableCell";
import moment from "moment";
import {
  EllipsisOutlined,
  StopOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { useRef, useState } from "react";

export function UsersListPage() {
  const { http } = useSession();
  const apiRef = useRef<RecordsTableApi<UserType> | undefined>(undefined);
  const [exportLoading, setExportLoading] = useState(false);

  const columns = [
    {
      dataIndex: "person",
      title: "Person",
      render: (person: PersonType) => {
        return <PersonTableCell person={person} />;
      },
    },
    {
      dataIndex: "email",
      title: "Email",
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (status: UserStatusType) => {
        const colorMap: Record<UserStatusType, string> = {
          ACTIVE: "green",
          LOCKED: "yellow",
          BLOCKED: "grey",
          DELETED: "red",
        };

        return <Tag color={colorMap[status]}>{status || "N/A"}</Tag>;
      },
    },
    {
      dataIndex: "latestSubscription",
      title: "Subscription",
      render: (subscription: any) => {
        if (!subscription) {
          return <Tag color="grey">N/A</Tag>;
        }
        const colorMap: Record<string, string> = {
          ACTIVE: "green",
          EXPIRED: "yellow",
          INACTIVE: "grey",
          UNKNOWN: "red",
        };
        return <Tag color={colorMap[subscription.status]}>{subscription.status || "N/A"}</Tag>;
      }
    },
    {
      dataIndex: "latestSubscription",
      title: "Subscription type",
      render: (subscription: any) => {
        if (!subscription) {
          return <Tag color="grey">N/A</Tag>;
        }
        const { billingPeriodUnit } = subscription;
        if (!billingPeriodUnit) {
          return <Tag color="grey">N/A</Tag>;
        }
        return <Tag color="green">{billingPeriodUnit.toUpperCase()}</Tag>;
      }
    },
    {
      dataIndex: "latestSubscription",
      title: "Next billing cycle",
      render: (subscription: any) => {
        if (!subscription) {
          return <Tag color="grey">N/A</Tag>;
        }
        const { nextBillingAt } = subscription;
        if (!nextBillingAt) {
          return <Tag color="grey">N/A</Tag>;
        }
        const nextBillingTime = moment(nextBillingAt);
        if (moment().isSame(nextBillingTime, "day")) {
          return nextBillingTime.fromNow();
        }
        return nextBillingTime.format("lll");
      }
    },
    {
      dataIndex: "lastLoginTime",
      title: "Last login",
      render: (timestamp: string) => {
        const lastLoginTime = moment(timestamp);

        if (!timestamp) {
          return <Tag color="grey">N/A</Tag>;
        }

        if (moment().isSame(lastLoginTime, "day")) {
          return lastLoginTime.fromNow();
        }

        return lastLoginTime.format("lll");
      },
    },
    {
      dataIndex: "socialType",
      title: "Registration Type",
      render: (type: UserSocialType) => {
        let title: string = type;

        if (title === "NONE") {
          title = "EMAIL";
        }

        return <Tag>{title}</Tag>;
      },
    },
    {
      key: "actions",
      title: "",
      width: "1%",
      render: (_: any, user: UserType) => {
        const menu = (
          <Menu>
            {["BLOCKED", "LOCKED"].includes(user.status) && (
              <Menu.Item
                icon={<UnlockOutlined />}
                onClick={() => changeUserStatus(user, "ACTIVE")}
              >
                Unblock User
              </Menu.Item>
            )}

            {!["BLOCKED", "DELETED"].includes(user.status) && (
              <Menu.Item
                icon={<StopOutlined />}
                danger
                onClick={() => changeUserStatus(user, "BLOCKED")}
              >
                Block User
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined style={{ fontSize: 24 }} color="#888" />
          </Dropdown>
        );
      },
    },
  ];

  async function changeUserStatus(user: UserType, status: UserStatusType) {
    const close = message.loading("Change user status...");

    try {
      await http.patch(`/users/${user.id}/status`, null, {
        params: { status },
      });

      apiRef.current?.refresh();
      close();
    } catch (ex) {
      message.error("Something went wrong.");
      close();
    }
  }

  async function fetchUsers(params: RecordsTableFetchParams) {
    const response = await http.get(`/users`, {
      params: recordsToFetchParams(params),
    });

    return parseListResponse<UserType>(response);
  }

  async function downloadUsers() {
    setExportLoading(true);
    try {
      const resp = await http.get("/media/export");
      setExportLoading(false);

      const { url } = resp.data;
      const el: HTMLAnchorElement = document.createElement("a");
      el.setAttribute("href", url);
      document.body.append(el);
      el.click();
      el.remove();
    } catch (ex) {
      message.error("Something went wrong.");
      setExportLoading(false);
    }
  }

  return (
    <PageHeader
      title="Users"
      breadcrumbRender={() => <PageBreadcrumbs items={[{ name: "Users" }]} />}
      extra={[
        <Button
          key="export"
          loading={exportLoading}
          onClick={() => downloadUsers()}
        >
          Export to CSV
        </Button>,
      ]}
    >
      <RecordsProvider<UserType>
        apiRef={apiRef}
        resource="users"
        columns={columns}
        fetchRecords={fetchUsers}
        initialPageSize={20}
      >
        <RecordsTableHeader />
        <RecordsTable size="small" />
      </RecordsProvider>
    </PageHeader>
  );
}

import React, { useState } from "react";
import { Button, Col, message, PageHeader, Row } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { RaylistForm } from "./RaylistForm";
import { useSession } from "../../providers/SessionProvider";
import { useHistory } from "react-router-dom";

export function RaylistCreatePage() {
  const { http } = useSession();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  async function handleCreateRayList(payload: any) {
    try {
      setLoading(true);
      await http.post("/ray-list", payload);
      message.success("RayList was successfully created.");
      setLoading(false);
      history.push("/raylists");
    } catch (ex) {
      setLoading(false);
      message.error("Could not create a RayList.");
    }
  }

  return (
    <PageHeader
      title="Create a Raylist"
      onBack={() => history.push("/raylists")}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[{ name: "Raylists", to: "/raylists" }, { name: "Create" }]}
        />
      )}
    >
      <Row>
        <Col span={12}>
          <RaylistForm onFinish={handleCreateRayList}>
            <Button htmlType="submit" loading={loading} type="primary">
              Create
            </Button>
          </RaylistForm>
        </Col>
      </Row>
    </PageHeader>
  );
}

import { useRecordLoader } from "../../components/useRecordLoader";
import { CategoryType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { PageLoader } from "../../components/PageLoader";
import { useParams } from "react-router-dom";

export function useCategoryLoader() {
  const { http } = useSession();

  const fetchCategoriesRecords = useCallback(
    async (id: string) => {
      const resp = await http.get(`/categories/${id}`);
      const category = resp.data as CategoryType;

      if (category.parentCategoryId) {
        category.parentCategory = (
          await http.get(`/categories/${category.parentCategoryId}`)
        ).data as CategoryType;
      }

      return category;
    },
    [http]
  );

  return useRecordLoader<CategoryType>({
    idParam: "categoryId",
    recordStateProp: "category",
    fetchRecord: fetchCategoriesRecords,
  });
}

export function CategoryPageLoader(props: any) {
  const { categoryId } = useParams<any>();

  return (
    <PageLoader
      key={categoryId}
      useRecordLoader={useCategoryLoader}
      {...props}
    />
  );
}

import { ArchetypeType, RaylistType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { useCallback, useRef, useState } from "react";
import {
  parseListResponse,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { CloseOutlined } from "@ant-design/icons";
import { RaylistTable } from "../ray-list/RaylistTable";
import { Button, Popconfirm } from "antd";
import { ArchetypeFeatureRaylistMappingModal } from "./ArchetypeFeatureRaylistMappingModal";

interface ArchetypeFeaturedTabParams {
  archetype: ArchetypeType;
}

export function ArchetypeFeaturedTab(props: ArchetypeFeaturedTabParams) {
  const { archetype } = props;
  const { http } = useSession();
  const [modalVisible, setModalVisible] = useState(false);

  const recordsApi = useRef<RecordsTableApi<RaylistType>>();

  async function unlinkFeatureRaylist(raylist: RaylistType) {
    await http.delete(
      `/archetypes/${archetype.id}/feature/ray-list/${raylist.id}`
    );
    recordsApi.current?.refresh();
  }

  const actions = (raylist: RaylistType) => (
    <Popconfirm
      title="Would you like to delete a featured raylist?"
      onConfirm={() => unlinkFeatureRaylist(raylist)}
      okText="Yes, Delete"
      cancelText="No"
      placement="topRight"
      okButtonProps={{ danger: true }}
    >
      <CloseOutlined />
    </Popconfirm>
  );

  const fetchArchetypeFeatureRaylists = useCallback(
    async (params: RecordsTableFetchParams) => {
      const resp = await http.get(
        `/ray-list/archetypes/${archetype.id}/feature`,
        {
          params: recordsToFetchParams(params),
        }
      );

      return parseListResponse<RaylistType>(resp);
    },
    [http, archetype.id]
  );

  function closeModal() {
    setModalVisible(false);
    recordsApi.current?.refresh();
  }

  return (
    <>
      <ArchetypeFeatureRaylistMappingModal
        archetype={archetype}
        visible={modalVisible}
        onCancel={closeModal}
      />

      <RaylistTable
        apiRef={recordsApi}
        actions={actions}
        resource={`${archetype.id}.featureRaylists`}
        fetchRecords={fetchArchetypeFeatureRaylists}
        tableHeaderProps={{
          extra: (
            <Button type="primary" onClick={() => setModalVisible(true)}>
              Add a Raylist
            </Button>
          ),
        }}
      />
    </>
  );
}

import { UseRecordValue, UseExactRecordValue } from "./useRecordLoader";
import { Button, Result, Skeleton } from "antd";
import React, { createContext, useContext } from "react";
import _ from "lodash";

interface PageLoaderProps<RecordType> {
  useRecordLoader(): UseRecordValue<RecordType>;
  render(props: any): React.ReactNode;
}

const PageLoaderContext = createContext<UseRecordValue<any> | undefined>(
  undefined
);

export function usePageRecord<RecordType>() {
  const recordValue = useContext(PageLoaderContext);

  if (recordValue === undefined) {
    throw new Error("usePageRecord must be inside PageLoader component.");
  }

  return recordValue as UseExactRecordValue<RecordType>;
}

export function PageLoader<RecordType>(props: PageLoaderProps<RecordType>) {
  const { useRecordLoader, render } = props;
  const recordValue = useRecordLoader();
  const { loading, record, error, loadRecord } = recordValue;

  if (loading && !record) {
    return (
      <div style={{ padding: "0 20px" }}>
        <Skeleton active />
      </div>
    );
  }

  if (error) {
    const status = _.get(error, "response.status", "500");
    const title = _.get(error, "response.status", "Ops...");

    return (
      <Result
        status={status}
        title={title}
        subTitle="Sorry, the page you visited can't be loaded."
        extra={
          <Button onClick={() => loadRecord()} type="primary">
            Retry
          </Button>
        }
      />
    );
  }

  console.log("page record: ", recordValue);

  return (
    <PageLoaderContext.Provider value={recordValue}>
      {render({ pageRecord: recordValue })}
    </PageLoaderContext.Provider>
  );
}

import { ArchetypeType, PersonType } from "../../types";
import { PersonTable } from "../person/PersonTable";
import { Button, Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  parseListResponse,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { useSession } from "../../providers/SessionProvider";
import { useRef, useState } from "react";
import { ArchetypePersonMappingModal } from "./ArchetypePersonMappingModal";

interface ArchetypePersonsTabProps {
  archetype: ArchetypeType;
}

export function ArchetypePersonsTab(props: ArchetypePersonsTabProps) {
  const { archetype } = props;
  const { http } = useSession();
  const apiRef = useRef<RecordsTableApi<PersonType>>();
  const [visible, setVisible] = useState(false);

  async function unlinkPerson(person: PersonType) {
    await http.delete(`/archetypes/${archetype.id}/person/${person.id}`);
    apiRef.current?.refresh();
  }

  const actions = (person: PersonType) => (
    <Popconfirm
      title="Would you like to delete a person?"
      onConfirm={() => unlinkPerson(person)}
      okText="Yes, Delete"
      cancelText="No"
      placement="topRight"
      okButtonProps={{ danger: true }}
    >
      <CloseOutlined />
    </Popconfirm>
  );

  async function fetchArchetypePersons(params: RecordsTableFetchParams) {
    const resp = await http.get(`/persons/archetypes/${archetype.id}/`, {
      params: recordsToFetchParams(params),
    });

    return parseListResponse<PersonType>(resp);
  }

  function hideModal() {
    setVisible(false);
    apiRef.current?.refresh();
  }

  return (
    <>
      <ArchetypePersonMappingModal
        archetype={archetype}
        visible={visible}
        onCancel={hideModal}
      />

      <PersonTable
        apiRef={apiRef}
        actions={actions}
        resource={`${archetype.id}.persons`}
        fetchRecords={fetchArchetypePersons}
        tableHeaderProps={{
          extra: (
            <Button type="primary" onClick={() => setVisible(true)}>
              Add a Person
            </Button>
          ),
        }}
      />
    </>
  );
}

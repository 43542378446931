import React, { useRef } from "react";
import { Button, PageHeader } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import {
  parseListResponse,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { CategoryType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { CategoryTable } from "./CategoryTable";
import { EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export function CategoryListPage() {
  const { http } = useSession();
  const history = useHistory();
  const apiRef = useRef<RecordsTableApi<CategoryType> | undefined>(undefined);

  async function fetchCategories(params: RecordsTableFetchParams) {
    const resp = await http.get("/categories/root", {
      params: {
        ...recordsToFetchParams(params),
        withSubcategories: true,
      },
    });

    return parseListResponse<CategoryType>(resp);
  }

  return (
    <PageHeader
      title="Categories"
      breadcrumbRender={() => (
        <PageBreadcrumbs items={[{ name: "Categories" }]} />
      )}
      extra={[
        <Button
          type="primary"
          onClick={() => history.push("/categories/create")}
        >
          Create
        </Button>,
      ]}
    >
      <CategoryTable
        resource="categories"
        fetchRecords={fetchCategories}
        apiRef={apiRef}
        action={(category) => (
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={() =>
              history.push({
                pathname: `/categories/${category.id}/edit`,
                state: { category },
              })
            }
          />
        )}
      />
    </PageHeader>
  );
}

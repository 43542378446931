import { List, ModalProps } from "antd";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { CategoryType, RaylistType } from "../../types";
import { ImagePreview } from "../../components/ImagePreview";
import _ from "lodash";
import update from "react-addons-update";
import { RecordsMappingModal } from "../../components/RecordsMappingModal";

interface CategoryRaylistMappingModalProps extends ModalProps {
  category: CategoryType;
}

export function CategoryRaylistMappingModal(
  props: CategoryRaylistMappingModalProps
) {
  const { category, ...modalPros } = props;
  const { http } = useSession();

  const handleSearch = useCallback(
    async (term: string) => {
      const resp = await http.get(`/ray-list`, {
        params: {
          ...(term.length > 0 && {
            search: term,
          }),
          size: 5,
        },
      });

      return resp.data.content;
    },
    [http]
  );

  const linkRaylist = useCallback(
    async (raylist: RaylistType) => {
      await http.post(
        `/category-ray-lists/category/${category.id}/ray-list/${raylist.id}`
      );
    },
    [http, category.id]
  );

  function linkRaylistUpdateState(
    raylists: RaylistType[],
    raylist: RaylistType
  ) {
    const index = _.findIndex(raylists, { id: raylist.id });

    return update(raylists, {
      [index]: {
        categories: {
          $push: [category],
        },
      },
    });
  }

  const unlinkRaylist = useCallback(
    async (raylist: RaylistType) => {
      await http.delete(
        `/category-ray-lists/category/${category.id}/ray-list/${raylist.id}`
      );
    },
    [http, category.id]
  );

  function unlinkRaylistUpdateState(
    raylists: RaylistType[],
    raylist: RaylistType
  ) {
    const index = _.findIndex(raylists, { id: raylist.id });
    const categoryIndex = _.findIndex(raylists?.[index].categories, {
      id: category.id,
    });

    return update(raylists as RaylistType[], {
      [index]: {
        categories: {
          $splice: [[categoryIndex, 1]],
        },
      },
    });
  }

  function renderRaylistItem(raylist: RaylistType, button: React.ReactNode) {
    return (
      <List.Item>
        <ImagePreview alt={raylist.caption} src={raylist.imageCardUrl} />

        <List.Item.Meta
          style={{ marginLeft: 10 }}
          title={raylist.caption}
          description={`${raylist.curator.firstName} ${raylist.curator.lastName}`}
        />

        {button}
      </List.Item>
    );
  }

  function isRaylistLinked(raylist: RaylistType) {
    return raylist.categories.findIndex((cat) => cat.id === category.id) !== -1;
  }

  return (
    <RecordsMappingModal<RaylistType>
      title="Add a Raylist"
      {...modalPros}
      search={handleSearch}
      isRecordLinked={isRaylistLinked}
      renderItem={renderRaylistItem}
      linkRecord={linkRaylist}
      linkRecordUpdateState={linkRaylistUpdateState}
      unlinkRecord={unlinkRaylist}
      unlinkRecordUpdateState={unlinkRaylistUpdateState}
    />
  );
}

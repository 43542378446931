import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Typography,
} from "antd";
import { Rule } from "antd/es/form";
import { PersonType } from "../../types";
import { ModalProps } from "antd/es/modal";
import { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { UploadS3File } from "../../components/Form/UploadS3File";

interface PersonFormModalProps extends ModalProps {
  onSubmit(person: PersonType, initial?: PersonType): Promise<any>;
  onDelete?(person: PersonType): Promise<void>;
  initialValues?: PersonType;
}

export function PersonFormModal(props: PersonFormModalProps) {
  const { onSubmit, initialValues, onDelete, ...modalProps } = props;
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [form] = Form.useForm<PersonType>();
  const stringRules: Rule[] = [{ max: 255, type: "string" }];
  const textAreaRules: Rule[] = [{ max: 1000, type: "string" }];

  async function handleFormSubmit(values: PersonType) {
    setLoading(true);
    try {
      await onSubmit(values, initialValues);
    } catch (ex) {
      message.error("Couldn't create a person");
    } finally {
      setLoading(false);
    }
  }

  const values =
    initialValues &&
    _.update(
      {
        ...initialValues,
      },
      "birthdate",
      moment
    );

  async function handleDelete() {
    setDeleteLoading(true);

    if (initialValues !== undefined) {
      await onDelete?.(initialValues);
    }

    setDeleteLoading(false);
  }

  return (
    <Modal
      {...modalProps}
      maskClosable={false}
      width={1000}
      footer={[
        <Button key="cancel" onClick={modalProps.onCancel}>
          Cancel
        </Button>,
        ...[
          onDelete ? (
            <Popconfirm
              title="Would you like to delete a person?"
              key="delete"
              okText="Yes, delete"
              onConfirm={handleDelete}
              okButtonProps={{ danger: true }}
            >
              <Button danger loading={deleteLoading}>
                Delete
              </Button>
            </Popconfirm>
          ) : null,
        ],
        <Button
          key="ok"
          loading={loading}
          type="primary"
          onClick={() => form.submit()}
        >
          {modalProps.okText || "Ok"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={values}
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <Row gutter={12}>
          <Col sm={24} md={12}>
            <Typography.Title level={5}>Person Information</Typography.Title>
            <Form.Item
              name="firstName"
              label="First name"
              rules={[{ required: true }, ...stringRules]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="lastName" label="Last name" rules={stringRules}>
              <Input />
            </Form.Item>

            <Form.Item name="gender" label="Gender">
              <Radio.Group>
                <Radio.Button value="FEMALE">Female</Radio.Button>
                <Radio.Button value="MALE">Male</Radio.Button>
                <Radio.Button value="OTHER">Other</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="location" label="Location" rules={stringRules}>
              <Input />
            </Form.Item>

            <Form.Item name="profession" label="Profession" rules={stringRules}>
              <Input />
            </Form.Item>

            <Form.Item name="birthdate" label="Birthdate">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>

            <Form.Item name="avatarUrl" label="Avatar">
              <UploadS3File fileType="PERSON_AVATAR" type="picture" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={textAreaRules}
            >
              <Input.TextArea style={{ minHeight: 120 }} />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Typography.Title level={5}>Links</Typography.Title>

            <Form.Item name={["links", "WEB_SITE"]} label="Website">
              <Input />
            </Form.Item>

            <Form.Item name={["links", "WIKIPEDIA"]} label="Wikipedia">
              <Input />
            </Form.Item>

            <Form.Item name={["links", "FACEBOOK"]} label="Facebook">
              <Input />
            </Form.Item>

            <Form.Item name={["links", "INSTAGRAM"]} label="Instagram">
              <Input />
            </Form.Item>

            <Form.Item name={["links", "PINTEREST"]} label="Pinterest">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

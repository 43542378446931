import React, { useCallback } from "react";
import { DebounceSearch, DebounceSearchProps } from "./DebounceSearch";
import { useRecords } from "../providers/RecordsProvider";

export function SearchFilter(props: Omit<DebounceSearchProps, "search">) {
  const [, recordsApi] = useRecords();

  const handleSearch = useCallback(
    async (text: string) => {
      if (text && text.length) {
        await recordsApi.setFilter("search", text);
      } else {
        await recordsApi.removeFilter("search");
      }
    },
    [recordsApi]
  );

  return <DebounceSearch {...props} allowClear search={handleSearch} />;
}

import React, { useState } from "react";
import { Button, Col, message, PageHeader, Row } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { ArchetypeForm } from "./ArchetypeForm";
import { useSession } from "../../providers/SessionProvider";
import { useHistory } from "react-router-dom";

export function ArchetypeCreatePage() {
  const [loading, setLoading] = useState(false);
  const { http } = useSession();
  const history = useHistory();

  async function handleSubmitForm(payload: any) {
    try {
      setLoading(true);
      await http.post("/archetypes", payload);
      setLoading(false);
      message.success("Archetype successfully created.");
      history.push("/archetypes");
    } catch (ex) {
      setLoading(false);
      message.error("Could not create an archetype.");
    }
  }

  return (
    <PageHeader
      title="Create an archetype"
      onBack={() => history.push("/archetypes")}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[
            { name: "Archetypes", to: "/archetypes" },
            { name: "Create an archetype" },
          ]}
        />
      )}
    >
      <Row>
        <Col span={12}>
          <ArchetypeForm onFinish={handleSubmitForm}>
            <Button htmlType="submit" type="primary" loading={loading}>
              Create
            </Button>
          </ArchetypeForm>
        </Col>
      </Row>
    </PageHeader>
  );
}

import React, { useState } from "react";
import { Button, PageHeader, message, Row, Col, Popconfirm } from "antd";
import { CategoryForm } from "./CategoryForm";
import { useSession } from "../../providers/SessionProvider";
import { useHistory, useLocation } from "react-router-dom";
import { usePageRecord } from "../../components/PageLoader";
import { CategoryType } from "../../types";
import { CategoryPageBreadcrumbs } from "./CategoryPageBreadcrumbs";

export function CategoryEditPage() {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const {
    record: category,
    recordId: categoryId,
  } = usePageRecord<CategoryType>();

  const { http } = useSession();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search.replace("?", ""));
  const parentCategoryId = searchParams.get("parentId") || null;

  function pushToList() {
    if (parentCategoryId) {
      history.push(`/categories/${parentCategoryId}/subcategories`);
    } else {
      history.push("/categories");
    }
  }

  async function updateCategory(payload: any) {
    setLoading(true);
    try {
      await http.put(`/categories/${categoryId}`, {
        ...payload,
        parentCategoryId,
      });
      setLoading(false);
      pushToList();
    } catch (ex) {
      setLoading(false);
      message.error(ex.message);
    }
  }

  async function handleDeleteCategory() {
    setLoadingDelete(true);

    try {
      await http.delete(`/categories/${categoryId}`);
      setLoadingDelete(false);
      message.success("Category successfully deleted.");
      pushToList();
    } catch (ex) {
      setLoadingDelete(false);
      message.error("Could not delete a category");
    }
  }

  return (
    <PageHeader
      title="Update a Category"
      subTitle={category.id}
      onBack={() => pushToList()}
      extra={
        <Popconfirm
          title="Do you really want to delete a category?"
          placement="bottomRight"
          okText="Yes, Delete"
          okButtonProps={{ danger: true }}
          onConfirm={handleDeleteCategory}
        >
          <Button danger loading={loadingDelete}>
            Delete
          </Button>
        </Popconfirm>
      }
      breadcrumbRender={() => (
        <CategoryPageBreadcrumbs
          extra={[{ name: "Update a Category" }]}
          category={category}
        />
      )}
    >
      <Row>
        <Col span={12}>
          <CategoryForm onFinish={updateCategory} initialValues={category}>
            <Button htmlType="submit" type="primary" loading={loading}>
              Update
            </Button>
          </CategoryForm>
        </Col>
      </Row>
    </PageHeader>
  );
}

import { useRecordLoader } from "../../components/useRecordLoader";
import { DeviceType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { PageLoader } from "../../components/PageLoader";

export function useDeviceLoader() {
  const { http } = useSession();

  const fetchDeviceRecords = useCallback(
    async (id: string) => {
      const respDev = await http.get(`/devices/${id}`);
      const respStatus = await http.get(`/devices/${id}/status`);
	  const respCurrentlyRaying = await http.get(`/currently-raying/${id}`);
      return {
        ...respDev.data,
        status: respStatus.data,
		currentlyRaying: respCurrentlyRaying.data
      } as DeviceType;
    },
    [http]
  );

  return useRecordLoader<DeviceType>({
    idParam: "deviceId",
    recordStateProp: "device",
    fetchRecord: fetchDeviceRecords,
  });
}

export function DevicePageLoader(props: any) {
  return <PageLoader useRecordLoader={useDeviceLoader} {...props} />;
}

import { DeviceStateType, DeviceStatusType, DeviceType } from "../../types";
import _ from "lodash";
import { Progress, Tag, Tooltip, Typography } from "antd";
import moment from "moment";

interface DeviceOnlineStatusProps {
  status: DeviceStatusType;
}

export function DeviceOnlineStatus(props: DeviceOnlineStatusProps) {
  const { status } = props;

  if (!_.has(status, "online")) {
    return <Typography.Text type="secondary">—</Typography.Text>;
  }

  const hbTime = moment(status.modifyTime);
  const lastSeen = moment().isSame(hbTime, "hour")
    ? hbTime.fromNow()
    : hbTime.format("lll");

  return (
    <Tooltip title={`last seen: ${lastSeen}`}>
      {status.online ? (
        <Tag color="green">Online</Tag>
      ) : (
        <Tag color="red">Offline</Tag>
      )}
    </Tooltip>
  );
}

interface DeviceStateProps {
  state: DeviceStateType;
}

export function DeviceState(props: DeviceStateProps) {
  const { state } = props;

  const colors: Record<DeviceStateType, string> = {
    PROVISIONED: "green",
    FROM_FACTORY: "blue",
    UNPROVISIONED: "default",
  };

  return <Tag color={colors[state]}>{state}</Tag>;
}

function signalStrengthToLevel(signal: number): [number, string] {
  const points: [number, number, string][] = [
    [-50, 100, "#52c41a"],
    [-60, 75, "#a0d911"],
    [-63.5, 60, "#d3f261"],
    [-67, 50, "#fadb14"],
    [-70, 25, "#faad14"],
    [-80, 10, "#fa541c"],
  ];

  for (const point of points) {
    const [level, value, color] = point;

    if (signal >= level) {
      return [value, color];
    }
  }

  return [0, "#f5222d"];
}

interface DeviceWifiSignalProps {
  status: DeviceStatusType;
  withTitle?: boolean;
}

export function DeviceWifiSignal(props: DeviceWifiSignalProps) {
  const { status, withTitle = false } = props;
  if (!status) {
    return <Typography.Text type="secondary">—</Typography.Text>;
  }

  const [value, color] = signalStrengthToLevel(status.wifiSignalRSS);

  return (
    <Tooltip
      {...(withTitle && { visible: false })}
      title={`${status.wifiName}: ${status.wifiSignalRSS}dBm`}
    >
      <Progress
        percent={value}
        format={() => (
          <Typography.Text style={{ fontFamily: "monospace" }}>
            <strong>{status.wifiName}</strong> {status.wifiSignalRSS}dBm
          </Typography.Text>
        )}
        steps={6}
        showInfo={withTitle}
        strokeColor={color}
      />
    </Tooltip>
  );
}

interface DeviceFWVersionProps {
  device: DeviceType;
}

export function DeviceStatusFWVersion(props: DeviceFWVersionProps) {
  const device = props.device;

  if (device.status?.firmwareVersionName) {
    return (
      <Tag>
        {device.status.firmwareVersionName} ({device.status.firmwareVersionCode}
        )
      </Tag>
    );
  }

  return <Typography.Text type="secondary">—</Typography.Text>;
}

export function DeviceFWVersion(props: DeviceFWVersionProps) {
  const { device } = props;

  return (
    <Tag>
      {device.firmwareVersionName} ({device.firmwareVersionCode})
    </Tag>
  );
}

import React, { useCallback } from "react";
import { Button, PageHeader, Space } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { Link, useHistory } from "react-router-dom";
import { RecordsTable } from "../../components/RecordsTable";
import {
  parseListResponse,
  RecordsProvider,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { useSession } from "../../providers/SessionProvider";
import { ArchetypeType } from "../../types";
import { RecordsTableHeader } from "../../components/RecordsTableHeader";
import { ImagePreview } from "../../components/ImagePreview";
import { EditOutlined } from "@ant-design/icons";

export function ArchetypeListPage() {
  const history = useHistory();
  const { http } = useSession();

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (title: string, archetype: ArchetypeType) => (
        <Space direction="horizontal" size={10}>
          <ImagePreview src={archetype.backgroundImageUrl} alt={title} />
          <Link
            to={{
              pathname: `/archetypes/${archetype.id}/details/raylists`,
              state: { archetype },
            }}
          >
            {title}
          </Link>
        </Space>
      ),
    },
    {
      title: "",
      key: "actions",
      width: "1%",
      render: (_: any, archetype: ArchetypeType) => (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() =>
            history.push({
              pathname: `/archetypes/${archetype.id}/edit`,
              // state: { archetype },
            })
          }
        />
      ),
    },
  ];

  const fetchArchetypesRecords = useCallback(
    async (params: RecordsTableFetchParams) => {
      const resp = await http.get("/archetypes", {
        params: recordsToFetchParams(params),
      });

      return parseListResponse<ArchetypeType>(resp);
    },
    [http]
  );

  return (
    <PageHeader
      title="Archetypes"
      breadcrumbRender={() => (
        <PageBreadcrumbs items={[{ name: "Archetypes" }]} />
      )}
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => history.push("/archetypes/create")}
          >
            Create
          </Button>
        </Space>
      }
    >
      <RecordsProvider<ArchetypeType>
        resource="archetypes"
        columns={columns}
        fetchRecords={fetchArchetypesRecords}
      >
        <RecordsTableHeader />
        <RecordsTable />
      </RecordsProvider>
    </PageHeader>
  );
}

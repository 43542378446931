import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import _ from "lodash";

export type BreadcrumbItem = {
  to?: string;
  key?: string;
  props?: any;
  name: string;
};

export interface PageBreadcrumbsProps {
  items: BreadcrumbItem[];
}

export function PageBreadcrumbs(props: PageBreadcrumbsProps) {
  useEffect(() => {
    const parts = [
      ...props.items.reverse().map((item) => item.name),
      "Presence",
      "Admin Panel",
    ];

    window.document.title = parts.join(" · ");
  }, [props.items]);

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>

      {props.items.map((item, index) => (
        <Breadcrumb.Item key={_.get(item, "key", index)}>
          {_.has(item, "to") ? (
            <Link {...item.props} to={item.to}>
              {item.name}
            </Link>
          ) : (
            <span {...item.props}>{item.name}</span>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

import { usePageRecord } from "../../components/PageLoader";
import { ArchetypeType } from "../../types";
import { Button, PageHeader, Tabs } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { useHistory, useParams } from "react-router-dom";
import { ArchetypeRaylistsTab } from "./ArchetypeRaylistsTab";
import { ArchetypePersonsTab } from "./ArchetypePersonsTab";
import { ArchetypeFeaturedTab } from "./ArchetypeFeaturedTab";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

interface ArchetypeDetailsPageParams {
  archetypeId: string;
  tab: string;
}

export function ArchetypeDetailsPage() {
  const { record: archetype } = usePageRecord<ArchetypeType>();
  const history = useHistory();
  const { tab } = useParams<ArchetypeDetailsPageParams>();

  return (
    <PageHeader
      title={archetype.title}
      subTitle={archetype.id}
      onBack={() => history.push("/archetypes")}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[
            { name: "Archetypes", to: "/archetypes" },
            { name: archetype.title },
          ]}
        />
      )}
      extra={[
        <Link
          to={{
            pathname: `/archetypes/${archetype.id}/edit`,
            state: { archetype },
          }}
        >
          <Button icon={<EditOutlined />}>Edit</Button>
        </Link>,
      ]}
    >
      <Tabs
        activeKey={tab}
        onChange={(key: string) =>
          history.push({
            pathname: `/archetypes/${archetype.id}/details/${key}`,
            state: { archetype },
          })
        }
      >
        <Tabs.TabPane key="raylists" tab="Raylists">
          <ArchetypeRaylistsTab archetype={archetype} />
        </Tabs.TabPane>

        <Tabs.TabPane key="persons" tab="Persons">
          <ArchetypePersonsTab archetype={archetype} />
        </Tabs.TabPane>

        <Tabs.TabPane key="featured" tab="Featured Raylists">
          <ArchetypeFeaturedTab archetype={archetype} />
        </Tabs.TabPane>
      </Tabs>
    </PageHeader>
  );
}

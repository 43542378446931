import { PersonTableCell } from "../person/PersonTableCell";
import { PersonType, RayStoryType, RayType } from "../../types";
import { RayStoryTableCell } from "./RayStoryTableCell";
import {
  RecordsProvider,
  RecordsProviderProps,
} from "../../providers/RecordsProvider";
import { RecordsTable } from "../../components/RecordsTable";
import { RecordsTableHeader } from "../../components/RecordsTableHeader";

interface RayTableProps extends Omit<RecordsProviderProps<RayType>, "columns"> {
  actions?: (ray: RayType) => React.ReactNode;
}

export function RayTable(props: RayTableProps) {
  const { actions, ...providerProps } = props;

  const columns = [
    {
      title: "Quote Text",
      dataIndex: "quoteText",
      width: "40%",
    },
    {
      title: "Quote Author",
      dataIndex: "quoteAuthor",
      width: "240px",
      render: (quoteAuthor: PersonType) => (
        <PersonTableCell person={quoteAuthor} />
      ),
    },
    {
      title: "Artist",
      dataIndex: "artist",
      width: "240px",
      render: (artist: PersonType) => <PersonTableCell person={artist} />,
    },
    {
      title: "Story",
      dataIndex: "story",
      render: (story: RayStoryType) => <RayStoryTableCell story={story} />,
    },
    {
      title: "",
      key: "actions",
      width: "1%",
      render: (a: any, ray: RayType) => actions?.(ray),
    },
  ];

  return (
    <RecordsProvider<RayType> {...providerProps} columns={columns}>
      <RecordsTableHeader />
      <RecordsTable />
    </RecordsProvider>
  );
}

import { useRecordLoader } from "../../components/useRecordLoader";
import { RaylistType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { PageLoader } from "../../components/PageLoader";

export function useRaylistLoader() {
  const { http } = useSession();

  const fetchRaylist = useCallback(
    async (id: string) => {
      const resp = await http.get(`/ray-list/${id}`);
      return resp.data as RaylistType;
    },
    [http]
  );

  return useRecordLoader<RaylistType>({
    fetchRecord: fetchRaylist,
    idParam: "raylistId",
    recordStateProp: "raylist",
  });
}

export function RaylistPageLoader(props: any) {
  return <PageLoader useRecordLoader={useRaylistLoader} {...props} />;
}

import React, { useCallback } from "react";
import { Space, Typography } from "antd";
import _ from "lodash";
import { useSession } from "../../providers/SessionProvider";
import { PersonType } from "../../types";
import { PersonAvatar } from "./PersonAvatar";
import { SelectRecord } from "../../components/Form/SelectRecord";

interface PersonSelectLabelProps {
  person: PersonType;
}

function PersonSelectLabel(props: PersonSelectLabelProps) {
  const { person } = props;

  return (
    <Space direction="horizontal" size={8}>
      <PersonAvatar person={person} />

      <Typography.Text>
        {person.firstName} {person.lastName}
      </Typography.Text>
    </Space>
  );
}

export function personToSelectOption(person: PersonType) {
  const fullName = [person.firstName, person.lastName]
    .filter(_.identity)
    .join(" ");

  return {
    key: person.id,
    label: <PersonSelectLabel person={person} />,
    text: fullName,
    value: person.id,
  };
}

export function PersonSelectField(props: any) {
  const { http } = useSession();

  const searchPerson = useCallback(
    async (term: string | null) => {
      const resp = await http.get("/persons", {
        params: {
          page: 0,
          size: 10,
          ...(term && { search: term }),
        },
      });

      const {
        data: { content },
      } = resp;

      return content?.map(personToSelectOption) || [];
    },
    [http]
  );

  const fetchPerson = useCallback(
    async (personId) => {
      const resp = await http.get(`/persons/${personId}`);

      const { data: person } = resp;
      return personToSelectOption(person);
    },
    [http]
  );

  return (
    <SelectRecord
      searchRecords={searchPerson}
      fetchRecord={fetchPerson}
      optionLabelProp="text"
      {...props}
    />
  );
}

import React, { useEffect, useMemo, useRef } from "react";
import { Skeleton, Table } from "antd";
import _ from "lodash";

import {
  RecordsTableApi,
  RecordsTableSorting,
  useRecords,
} from "../providers/RecordsProvider";
import {
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
  TableRowSelection,
} from "antd/es/table/interface";
import { ColumnType, TableProps } from "antd/es/table";

export interface RecordsTableProps<T> extends TableProps<T> {
  autoload?: boolean;
  selectable?: boolean;
}

function mapSortOrder(order: string) {
  const map = {
    asc: "ascend",
    desc: "descend",
    ascend: "asc",
    descend: "desc",
  };

  return _.get(map, order, order);
}

export function RecordsTable<RecordType extends object>(
  props: RecordsTableProps<RecordType>
) {
  const [state, recordsApi] = useRecords<RecordType>();
  const {
    autoload = true,
    selectable = false,
    rowSelection,
    ...restProps
  } = props;

  function onTableChange<T>(
    pagination: TablePaginationConfig,
    filters: Record<string, any>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) {
    switch (extra.action) {
      case "paginate":
        recordsApi.setPagination(pagination);
        break;

      case "filter":
        const f = {
          ...state.filters,
          ...filters,
        };
        recordsApi.setFilters(f);
        break;

      case "sort":
        const _sorting = _.castArray<SorterResult<T>>(
          sorter
        ).map<RecordsTableSorting>((s) => ({
          field: s.field?.toString() || "",
          order: mapSortOrder(s.order || ""),
        }));

        recordsApi.setSorting(_sorting);
        break;
    }
  }

  function columnKey(column: any) {
    return _.get(column, "key", column.dataIndex);
  }

  const columns = useMemo<ColumnType<RecordType>[]>(() => {
    const filteredColumns = state.columns.filter(
      (column) => !state.hiddenColumns.includes(columnKey(column))
    );

    return filteredColumns.map((column) => {
      const columnSort = _.find(state.sorting, { field: columnKey(column) });

      return {
        ...column,
        ...(column.sorter === true &&
          !_.has(column, "sortDirections") && {
            sortDirections: ["descend", "ascend", "descend"],
          }),
        ...(!_.isEmpty(columnSort) && {
          sortOrder: mapSortOrder(columnSort?.order || ""),
        }),
        className: `column-${columnKey(column)}`,
      };
    });
  }, [state.columns, state.hiddenColumns, state.sorting]);

  const recordsApiRef = useRef<RecordsTableApi<RecordType>>();
  recordsApiRef.current = recordsApi;

  useEffect(() => {
    if (autoload) {
      recordsApiRef.current?.refresh();
    }
  }, [autoload]);

  if (state.initial) {
    return <Skeleton />;
  }

  const selection: TableRowSelection<RecordType> = {
    ...rowSelection,
    onChange(selectedRowKeys, selectedRows) {
      recordsApi.setSelectedRecords(selectedRows);
    },
  };

  return (
    <Table<RecordType>
      size="middle"
      {...restProps}
      {...(selectable && { rowSelection: selection })}
      rowKey="id"
      columns={columns}
      dataSource={state.records}
      loading={state.loading}
      onChange={onTableChange}
      pagination={{
        ...state.pagination,
        showSizeChanger: true,
      }}
    />
  );
}

import { List, ModalProps } from "antd";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { ArchetypeType, RaylistType } from "../../types";
import { ImagePreview } from "../../components/ImagePreview";
import _ from "lodash";
import update from "react-addons-update";
import { RecordsMappingModal } from "../../components/RecordsMappingModal";

interface ArchetypeRaylistMappingModalProps extends ModalProps {
  archetype: ArchetypeType;
}

export function ArchetypeRaylistMappingModal(
  props: ArchetypeRaylistMappingModalProps
) {
  const { archetype, ...modalPros } = props;
  const { http } = useSession();

  const handleSearch = useCallback(
    async (term: string) => {
      const resp = await http.get(`/ray-list`, {
        params: {
          ...(term.length > 0 && {
            search: term,
          }),
          size: 5,
          withArchetypes: true,
        },
      });

      return resp.data.content;
    },
    [http]
  );

  const linkRaylist = useCallback(
    async (raylist: RaylistType) => {
      await http.post(`/archetypes/${archetype.id}/ray-list/${raylist.id}`);
    },
    [http, archetype.id]
  );

  function linkRaylistUpdateState(
    raylists: RaylistType[],
    raylist: RaylistType
  ) {
    const index = _.findIndex(raylists, { id: raylist.id });

    if (!raylists[index].archetypes) {
      raylists[index].archetypes = [];
    }

    return update(raylists, {
      [index]: {
        archetypes: {
          $push: [archetype],
        },
      },
    });
  }

  const unlinkRaylist = useCallback(
    async (raylist: RaylistType) => {
      await http.delete(`/archetypes/${archetype.id}/ray-list/${raylist.id}`);
    },
    [http, archetype.id]
  );

  function unlinkRaylistUpdateState(
    raylists: RaylistType[],
    raylist: RaylistType
  ) {
    const index = _.findIndex(raylists, { id: raylist.id });
    const categoryIndex = _.findIndex(raylists?.[index].archetypes, {
      id: archetype.id,
    });

    return update(raylists, {
      [index]: {
        archetypes: {
          $splice: [[categoryIndex, 1]],
        },
      },
    });
  }

  function renderRaylistItem(raylist: RaylistType, button: React.ReactNode) {
    return (
      <List.Item>
        <ImagePreview alt={raylist.caption} src={raylist.imageCardUrl} />

        <List.Item.Meta
          style={{ marginLeft: 10 }}
          title={raylist.caption}
          description={`${raylist.curator.firstName} ${raylist.curator.lastName}`}
        />

        {button}
      </List.Item>
    );
  }

  function isRaylistLinked(raylist: RaylistType) {
    const { archetypes = [] } = raylist;
    return archetypes.findIndex((next) => next.id === archetype.id) !== -1;
  }

  return (
    <RecordsMappingModal<RaylistType>
      title="Add a Raylist"
      {...modalPros}
      search={handleSearch}
      isRecordLinked={isRaylistLinked}
      renderItem={renderRaylistItem}
      linkRecord={linkRaylist}
      linkRecordUpdateState={linkRaylistUpdateState}
      unlinkRecord={unlinkRaylist}
      unlinkRecordUpdateState={unlinkRaylistUpdateState}
    />
  );
}

import { useMemo, useState, useEffect } from "react";
import { DeviceSettingsType, DeviceType } from "../../types";
import {
  Card,
  Descriptions,
  Switch,
  Button,
  Space,
  Select,
  Slider,
  message,
} from "antd";
import _ from "lodash";
import { useSession } from "../../providers/SessionProvider";

interface DeviceUpdateSettingsProp {
  device: DeviceType;
  loadRecord: () => void;
}

export function DevicesUpdateSettings(props: DeviceUpdateSettingsProp) {
  const { device, loadRecord } = props;
  const { http } = useSession();
  const [reloadPage, setReloadPage] = useState(false);

  const [settings, setSettings] = useState<DeviceSettingsType>(device.settings);

  const isDirty = useMemo(() => {
    return !_.isEqual(device.settings, settings);
  }, [device.settings, settings]);

  function updateSettings(key: string, value: any) {
    setSettings((currentSettings) => ({ ...currentSettings, [key]: value }));
  }

  useEffect(() => {
    if (reloadPage) {
      setReloadPage(false);
      loadRecord();
    }
  }, [reloadPage, loadRecord]);

  const onHandleSaveSettings = async () => {
    const deviceId = device.status.deviceId;

    // logLevel
    const logLevelIsEqual = !_.isEqual(
      device.settings.logLevel,
      settings.logLevel
    );

    // hbRefreshTimeSec
    const refreshRateIsEqual = !_.isEqual(
      device.settings.hbRefreshTimeSec,
      settings.hbRefreshTimeSec
    );

    // raySpeed
    const reySpeedIsEqual = !_.isEqual(
      device.settings.raySpeed,
      settings.raySpeed
    );

    // brightnessAuto
    const screenBrightnessAutoIsEqual = !_.isEqual(
      device.settings.brightnessAuto,
      settings.brightnessAuto
    );

    // screenBrightness
    const screenBrightnessIsEqual = !_.isEqual(
      device.settings.screenBrightness,
      settings.screenBrightness
    );

    if (logLevelIsEqual) {
      await http
        .patch(`/devices/${deviceId}/settings/log-level`, null, {
          params: { logLevel: settings.logLevel },
        })
        .then(() => {
          message.success("Log Level successfully updated");
          setReloadPage(true);
        });
    }

    if (refreshRateIsEqual) {
      await http
        .patch(`/devices/${deviceId}/settings/hb-refresh-time`, null, {
          params: { hbRefreshTimeSec: settings.hbRefreshTimeSec },
        })
        .then(() => {
          message.success("HB refresh rate successfully updated");
          setReloadPage(true);
        });
    }

    if (reySpeedIsEqual) {
      await http
        .patch(`/devices/${deviceId}/settings/ray-speed`, null, {
          params: { raySpeed: settings.raySpeed },
        })
        .then(() => {
          message.success("Ray speed successfully updated");
          setReloadPage(true);
        });
    }

    if (screenBrightnessAutoIsEqual) {
      await http
        .patch(`/devices/${deviceId}/settings/brightness-auto`, null, {
          params: { brightnessAuto: settings.brightnessAuto },
        })
        .then(() => {
          message.success("Brightness successfully updated");
          setReloadPage(true);
        });
    }

    if (screenBrightnessIsEqual) {
      await http
        .patch(`/devices/${deviceId}/settings/screen-brightness`, null, {
          params: { screenBrightness: settings.screenBrightness },
        })
        .then(() => {
          message.success("Screen brightness successfully updated");
          setReloadPage(true);
        });
    }
  };

  return (
    <Card
      title="Settings"
      size="small"
      bodyStyle={{ padding: 0 }}
      style={{ maxWidth: 800 }}
      extra={
        <Space direction="horizontal" size={4}>
          <Button key="settings-cancel" disabled={!isDirty}>
            Discard
          </Button>

          <Button
            onClick={onHandleSaveSettings}
            disabled={!isDirty}
            type="primary"
            key="settings-update"
          >
            Save
          </Button>
        </Space>
      }
    >
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Screen brightness">
          <Slider
            value={settings.screenBrightness}
            style={{ width: "100%" }}
            disabled={settings.brightnessAuto}
            max={1}
            min={0}
            step={0.01}
            onChange={(v: number) =>
              updateSettings("screenBrightness", Number(v))
            }
          />
        </Descriptions.Item>

        <Descriptions.Item label="Brightness Auto">
          <Switch
            checked={settings.brightnessAuto}
            onChange={(v) => updateSettings("brightnessAuto", v)}
          />
        </Descriptions.Item>

        {/* <Descriptions.Item label="Ray speed / Min."> */}
        <Descriptions.Item label="Ray speed">
          <Select
            value={settings.raySpeed}
            style={{ width: 150 }}
            onSelect={(v) => updateSettings("raySpeed", Number(v))}
          >
            {/* <Select.Option value={0}>0 min</Select.Option>
            <Select.Option value={5 * 60}>5 min</Select.Option>
            <Select.Option value={15 * 60}>15 min</Select.Option>
            <Select.Option value={60 * 60}>1 hour</Select.Option>
            <Select.Option value={1440 * 60}>24 hours</Select.Option> */}
			<Select.Option value={57600}>Least Often-57600</Select.Option>
            <Select.Option value={28800}>Slower-28800</Select.Option>
            <Select.Option value={10800}>Normal-10800</Select.Option>
            <Select.Option value={3600}>Faster-3600</Select.Option>
            <Select.Option value={1200}>Most Often-1200</Select.Option>
          </Select>
        </Descriptions.Item>

        <Descriptions.Item label="HB refresh rate / Sec.">
          <Select
            value={settings.hbRefreshTimeSec}
            style={{ width: 120 }}
            onSelect={(v) => updateSettings("hbRefreshTimeSec", Number(v))}
          >
            <Select.Option value={30}>30</Select.Option>
            <Select.Option value={60}>60</Select.Option>
            <Select.Option value={120}>120</Select.Option>
            <Select.Option value={180}>180</Select.Option>
            <Select.Option value={240}>240</Select.Option>
            <Select.Option value={480}>480</Select.Option>
          </Select>
        </Descriptions.Item>

        <Descriptions.Item label="Log Level">
          <Select
            value={settings.logLevel}
            style={{ width: 120 }}
            onSelect={(v) => updateSettings("logLevel", v)}
          >
            <Select.Option value="TRACE">TRACE</Select.Option>
            <Select.Option value="DEBUG">DEBUG</Select.Option>
            <Select.Option value="INFO">INFO</Select.Option>
            <Select.Option value="WARN">WARN</Select.Option>
            <Select.Option value="ERROR">ERROR</Select.Option>
            <Select.Option value="FATAL">FATAL</Select.Option>
            <Select.Option value="OFF">OFF</Select.Option>
          </Select>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

import React from "react";
import { Avatar } from "antd";
import _ from "lodash";

import { PersonType } from "../../types";
import { AvatarProps } from "antd/es";

interface PersonAvatarProps extends AvatarProps {
  person: PersonType;
}

export function PersonAvatar(props: PersonAvatarProps) {
  const { person, ...avatarProps } = props;

  const ab = [person.firstName, person.lastName]
    .filter(_.identity)
    .map(_.first)
    .join("")
    .toUpperCase();

  return (
    <Avatar src={person.avatarUrl} {...avatarProps}>
      {ab}
    </Avatar>
  );
}

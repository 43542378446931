import { List, ModalProps, Typography } from "antd";
import { RaylistType, RayType } from "../../types";
import { useCallback } from "react";
import _ from "lodash";
import { useSession } from "../../providers/SessionProvider";
import update from "react-addons-update";
import { RecordsMappingModal } from "../../components/RecordsMappingModal";

interface RaylistRayMappingProps extends ModalProps {
  raylist: RaylistType;
}

export function RaylistRayMappingModal(props: RaylistRayMappingProps) {
  const { raylist, ...modalProps } = props;
  const { http } = useSession();

  const handleSearch = useCallback(
    async (search: string) => {
      const resp = await http.get("/rays/", {
        params: {
          ...(search.length > 0 && { search }),
          size: 5,
        },
      });

      return resp.data.content;
    },
    [http]
  );

  async function linkRay(ray: RayType) {
    await http.post(`/ray-list-mappings/ray/${ray.id}/ray-list/${raylist.id}`);
  }

  function linkRayUpdateState(rays: RayType[], ray: RayType) {
    const rayIndex = _.findIndex(rays, { id: ray.id });

    return update(rays, {
      [rayIndex]: {
        rayLists: {
          $push: [raylist],
        },
      },
    });
  }

  async function unlinkRay(ray: RayType) {
    await http.delete(
      `/ray-list-mappings/ray/${ray.id}/ray-list/${raylist.id}`
    );
  }

  function unlinkRayUpdateState(rays: RayType[], ray: RayType) {
    const rayIndex = _.findIndex(rays, { id: ray.id });

    const raylistIndex = _.findIndex(rays?.[rayIndex].rayLists, {
      id: raylist.id,
    });

    return update(rays, {
      [rayIndex]: {
        rayLists: {
          $splice: [[raylistIndex, 1]],
        },
      },
    });
  }

  function isRayLinked(ray: RayType) {
    return _.map(ray.rayLists, "id").includes(raylist.id);
  }

  function isRayDisabled(ray: RayType) {
    return _.without(_.map(ray.rayLists, "id"), raylist.id).length > 0;
  }

  function renderRay(ray: RayType, button: React.ReactNode) {
    return (
      <List.Item>
        <List.Item.Meta
          title={<Typography.Text>{ray.quoteText}</Typography.Text>}
          description={`${ray.quoteAuthor.firstName} ${ray.quoteAuthor.lastName}`}
        />

        {button}
      </List.Item>
    );
  }

  return (
    <RecordsMappingModal
      title="Add a Ray"
      {...modalProps}
      search={handleSearch}
      linkRecord={linkRay}
      linkRecordUpdateState={linkRayUpdateState}
      unlinkRecord={unlinkRay}
      unlinkRecordUpdateState={unlinkRayUpdateState}
      isRecordLinked={isRayLinked}
      isRecordDisabled={isRayDisabled}
      renderItem={renderRay}
    />
  );
}

import { Route, RouteProps } from "react-router-dom";

interface RecordLoaderProps {
  render: (props: any) => React.ReactNode;
}

interface RecordRouteProps extends RouteProps {
  loader: React.ComponentType<RecordLoaderProps>;
}

export function RecordRoute(props: RecordRouteProps) {
  const { loader: PageLoader, component: Page, ...routeProps } = props;

  if (!Page) {
    throw new Error("You must provide component prop for RecordRoute");
  }

  return (
    <Route
      {...routeProps}
      render={(componentProps) => (
        <PageLoader
          render={(loaderProps) => (
            <Page {...componentProps} {...loaderProps} />
          )}
        />
      )}
    />
  );
}

import {
  RecordsProvider,
  RecordsProviderProps,
} from "../../providers/RecordsProvider";
import { PersonType } from "../../types";
import { PersonTableCell } from "./PersonTableCell";
import { Tag, Typography } from "antd";
import {
  RecordsTableHeader,
  RecordsTableHeaderProps,
} from "../../components/RecordsTableHeader";
import { RecordsTable } from "../../components/RecordsTable";

export interface PersonTableProps
  extends Omit<RecordsProviderProps<PersonType>, "columns"> {
  actions(person: PersonType): React.ReactNode;
  tableHeaderProps?: RecordsTableHeaderProps;
}

export function PersonTable(props: PersonTableProps) {
  const { actions, tableHeaderProps, ...otherProps } = props;

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      width: "20%",
      render: (a: any, person: PersonType) => (
        <PersonTableCell person={person} />
      ),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (gender: string) => (gender ? <Tag>{gender}</Tag> : "---"),
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Profession",
      dataIndex: "profession",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "35%",
      render: (description: string) => (
        <Typography.Paragraph
          type="secondary"
          ellipsis={{ rows: 2, expandable: true }}
        >
          {description}
        </Typography.Paragraph>
      ),
    },
    {
      title: "",
      width: "1%",
      key: "actions",
      render: (_: any, person: PersonType) => actions(person),
    },
  ];

  return (
    <RecordsProvider<PersonType> columns={columns} {...otherProps}>
      <RecordsTableHeader {...tableHeaderProps} />
      <RecordsTable />
    </RecordsProvider>
  );
}

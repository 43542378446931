import { ArchetypeType, RaylistType } from "../../types";
import {
  parseListResponse,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { RaylistTable } from "../ray-list/RaylistTable";
import { useCallback, useRef, useState } from "react";
import { useSession } from "../../providers/SessionProvider";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { ArchetypeRaylistMappingModal } from "./ArchetypeRaylistMappingModal";

interface ArchetypeRaylistsTabProps {
  archetype: ArchetypeType;
}

export function ArchetypeRaylistsTab(props: ArchetypeRaylistsTabProps) {
  const { archetype } = props;
  const { http } = useSession();
  const [modalVisible, setModalVisible] = useState(false);

  const recordsApi = useRef<RecordsTableApi<RaylistType>>();

  async function unlinkRaylist(raylist: RaylistType) {
    await http.delete(`/archetypes/${archetype.id}/ray-list/${raylist.id}`);
    recordsApi.current?.refresh();
  }

  const actions = (raylist: RaylistType) => (
    <Popconfirm
      title="Would you like to delete a Raylist?"
      onConfirm={() => unlinkRaylist(raylist)}
      okText="Yes, Delete"
      cancelText="No"
      placement="topRight"
      okButtonProps={{ danger: true }}
    >
      <CloseOutlined />
    </Popconfirm>
  );

  const fetchArchetypeRaylists = useCallback(
    async (params: RecordsTableFetchParams) => {
      const resp = await http.get(`/ray-list/archetypes/${archetype.id}`, {
        params: recordsToFetchParams(params),
      });

      return parseListResponse<RaylistType>(resp);
    },
    [http, archetype.id]
  );

  function closeModal() {
    setModalVisible(false);
    recordsApi.current?.refresh();
  }

  return (
    <>
      <ArchetypeRaylistMappingModal
        archetype={archetype}
        visible={modalVisible}
        onCancel={closeModal}
      />

      <RaylistTable
        apiRef={recordsApi}
        actions={actions}
        resource={`${archetype.id}.raylists`}
        fetchRecords={fetchArchetypeRaylists}
        tableHeaderProps={{
          extra: (
            <Button type="primary" onClick={() => setModalVisible(true)}>
              Add a Raylist
            </Button>
          ),
        }}
      />
    </>
  );
}

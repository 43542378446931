import React, { ReactNode } from "react";
import { Col, Row, Space } from "antd";
import { SearchFilter } from "./SearchFilter";
import { SelectTableColumns } from "./SelectTableColumns";
import { RefreshButton } from "./RefreshButton";

export interface RecordsTableHeaderProps {
  children?: ReactNode;
  extra?: ReactNode;
}

export function RecordsTableHeader(props: RecordsTableHeaderProps) {
  return (
    <div style={{ paddingBottom: 12 }}>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <SearchFilter placeholder="Type to search" />
        </Col>

        <Col span={8}>{props.children}</Col>

        <Col span={8} style={{ textAlign: "right" }}>
          <Space size={8} direction="horizontal">
            {props.extra}
            <SelectTableColumns />
            <RefreshButton />
          </Space>
        </Col>
      </Row>
    </div>
  );
}

import { RecordsTable } from "../../components/RecordsTable";
import { ImagePreview } from "../../components/ImagePreview";
import { Link } from "react-router-dom";
import { CategoryType } from "../../types";
import { Space, Typography } from "antd";
import lodash from "lodash";
import {
  RecordsProvider,
  RecordsProviderProps,
} from "../../providers/RecordsProvider";
import React from "react";
import { RecordsTableHeader } from "../../components/RecordsTableHeader";

interface CategoryTableProps
  extends Omit<RecordsProviderProps<CategoryType>, "columns"> {
  action(item: CategoryType): React.ReactNode;
  parentCategory?: CategoryType;
  withLinks?: boolean;
}

export function CategoryTable(props: CategoryTableProps) {
  const { withLinks = true } = props;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name: string, category: CategoryType) => (
        <Space direction="horizontal" size={10}>
          <ImagePreview src={category.imageCardUrl} />

          {withLinks ? (
            <Link
              to={{
                pathname: `/categories/${category.id}/subcategories`,
                state: { category },
              }}
            >
              {name}
            </Link>
          ) : (
            <Typography>{name}</Typography>
          )}
        </Space>
      ),
    },
    {
      title: "Subtitle",
      dataIndex: "subtitle",
    },
    {
      title: "Raylists",
      key: "raylist_link",
      render: (_: any, category: CategoryType) =>
        lodash.isEmpty(category.childCategories) && (
          <Link
            to={{
              pathname: `/categories/${category.id}/raylists`,
              state: {
                category: {
                  ...category,
                  parentCategory: props.parentCategory,
                },
              },
            }}
          >
            Raylists
          </Link>
        ),
    },
    {
      title: "",
      key: "actions",
      width: "1%",
      render: (_: any, category: CategoryType) => props.action(category),
    },
  ];

  return (
    <RecordsProvider<CategoryType> {...props} columns={columns}>
      <RecordsTableHeader />
      <RecordsTable />
    </RecordsProvider>
  );
}

import React, { ImgHTMLAttributes } from "react";

interface ImagePreviewProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: number;
  width?: number;
  height?: number;
}

export function ImagePreview(props: ImagePreviewProps) {
  const {
    alt,
    size = 60,
    width = size,
    height = (size * 2) / 3,
    ...imageProps
  } = props;
  return (
    <img
      alt={alt}
      {...imageProps}
      style={{
        width,
        height,
        objectFit: "cover",
        borderRadius: 2,
        ...props.style,
      }}
    />
  );
}

import { List, Modal, ModalProps, Skeleton, Space } from "antd";
import { DebounceSearch } from "./DebounceSearch";
import { useCallback, useEffect, useState } from "react";
import { CheckOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { ProcessButton } from "./ProcessButton";

export interface MappingModalProps<RecordType> extends ModalProps {
  search(term: string): Promise<RecordType[]>;

  linkRecord(record: RecordType): Promise<any>;
  linkRecordUpdateState(
    records: RecordType[],
    record: RecordType
  ): RecordType[];

  unlinkRecord(record: RecordType): Promise<any>;
  unlinkRecordUpdateState(
    records: RecordType[],
    record: RecordType
  ): RecordType[];

  isRecordLinked(record: RecordType): boolean;
  isRecordDisabled?(record: RecordType): boolean;
  renderItem(record: RecordType, button: React.ReactNode): React.ReactNode;
}

export function RecordsMappingModal<RecordType extends { id: string }>(
  props: MappingModalProps<RecordType>
) {
  const {
    search,
    linkRecord,
    linkRecordUpdateState,
    unlinkRecord,
    unlinkRecordUpdateState,
    isRecordLinked,
    isRecordDisabled,
    renderItem,
    ...modalProps
  } = props;

  const [records, setRecords] = useState<RecordType[] | undefined>(undefined);

  const handleSearch = useCallback(
    async (term: string) => {
      const results = await search(term);
      setRecords(results);
    },
    [search]
  );

  async function linkRaylist(record: RecordType) {
    await linkRecord(record);
    setRecords(linkRecordUpdateState(records as RecordType[], record));
  }

  async function unlinkRaylist(record: RecordType) {
    await unlinkRecord(record);
    setRecords(unlinkRecordUpdateState(records as RecordType[], record));
  }

  function renderRecords(record: RecordType) {
    const isLinked = isRecordLinked(record);
    const isDisabled = isRecordDisabled?.(record) ?? false;

    const icon = isDisabled ? (
      <StopOutlined />
    ) : isLinked ? (
      <CheckOutlined />
    ) : (
      <PlusOutlined />
    );

    return (
      <div style={{ opacity: isDisabled ? 0.5 : 1 }}>
        {renderItem(
          record,
          <ProcessButton
            item={record}
            action={isLinked ? unlinkRaylist : linkRaylist}
            icon={icon}
            disabled={isDisabled}
            type={isLinked ? "primary" : "default"}
          />
        )}
      </div>
    );
  }

  useEffect(() => {
    handleSearch("");
  }, [handleSearch]);

  return (
    <Modal {...modalProps} footer={null}>
      <Space direction="vertical" size={12} style={{ width: "100%" }}>
        <DebounceSearch search={handleSearch} />

        {records === undefined ? (
          <Skeleton active />
        ) : (
          <List dataSource={records} renderItem={renderRecords} />
        )}
      </Space>
    </Modal>
  );
}

import { Input, InputProps } from "antd";
import { useEffect, useState, memo, useMemo } from "react";
import _ from "lodash";

export interface DebounceSearchProps extends Omit<InputProps, "onChange"> {
  wait?: number;
  search(term: string): Promise<any>;
}

export const DebounceSearch = memo(function DebounceSearch(
  props: DebounceSearchProps
) {
  const { wait = 500, search, ...inputProps } = props;
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState<string | undefined>(undefined);

  const debouncedSearch = useMemo(
    () =>
      _.debounce(async (term: string) => {
        setLoading(true);

        try {
          await search(term);
          setLoading(false);
        } catch (ex) {
          console.error(ex);
          setLoading(false);
        }
      }, wait),
    [search, wait]
  );

  useEffect(() => {
    if (term !== undefined) {
      debouncedSearch?.(term);
    }

    return () => {
      debouncedSearch?.cancel();
    };
  }, [term, debouncedSearch]);

  return (
    <Input.Search
      placeholder="Type to search"
      {...inputProps}
      onChange={(ev) => setTerm(ev.target.value)}
      loading={loading}
    />
  );
});

import React from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Typography,
} from "antd";
import { PersonSelectField } from "../person/PersonSelectField";
import { UploadS3File } from "../../components/Form/UploadS3File";
import { RayDOType, RayType } from "../../types";
import _ from "lodash";

interface RayFormProps extends FormProps {
  loading?: boolean;
  submitText?: string;
  ray?: RayType;
}

export function RayForm(props: RayFormProps) {
  const { loading, ray, submitText, ...formProps } = props;

  const initialValues = ray
    ? {
        ..._.omit(ray, ["quoteAuthorId", "artistId"]),
        quoteAuthorId: ray.quoteAuthor?.id,
        artistId: ray.artist?.id,
      }
    : {
        mediaData: { videoDurationInSeconds: 15 },
      };

  return (
    <Form<RayDOType>
      layout="vertical"
      {...formProps}
      initialValues={initialValues}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Typography.Title level={4}>Quote</Typography.Title>
          <Form.Item
            name="quoteText"
            label="Text"
            rules={[{ required: true }, { max: 1000 }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="quoteAuthorId"
            label="Author"
            rules={[{ required: true }]}
          >
            <PersonSelectField selectedPerson={ray?.quoteAuthor} />
          </Form.Item>

          <Form.Item
            name="artistId"
            label="Artist"
            rules={[{ required: true }]}
          >
            <PersonSelectField selectedPerson={ray?.artist} />
          </Form.Item>

          <Row>
            <Col span={8}>
              <Form.Item
                name="backgroundImageUrl"
                label="Background Image"
                rules={[{ required: true }]}
              >
                <UploadS3File fileType="RAY_BACKGROUND_IMAGE" type="picture" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="socialImageUrlSquare"
                label="Social Square Image "
                rules={[{ required: true }]}
              >
                <UploadS3File
                  fileType="RAY_SOCIAL_IMAGE_SQUARE"
                  type="picture"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="socialImageUrlStory"
                label="Social Story Image "
                rules={[{ required: true }]}
              >
                <UploadS3File
                  fileType="RAY_SOCIAL_IMAGE_STORY"
                  type="picture"
                />
              </Form.Item>
            </Col>
          </Row>

          <Typography.Title level={4}>Media</Typography.Title>

          <Form.Item
            name={["mediaData", "deviceVideoUrl"]}
            label="Device Video"
            rules={[{ required: true }]}
          >
            <UploadS3File fileType="RAY_DEVICE_VIDEO" />
          </Form.Item>

          <Form.Item
            name={["mediaData", "videoDurationInSeconds"]}
            label="Video Duration"
            rules={[{ required: true }, { type: "number" }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name={["mediaData", "appVideoUrl"]}
            label="App Video"
            rules={[{ required: true }]}
          >
            <UploadS3File fileType="RAY_APP_VIDEO" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Typography.Title level={4}>Story</Typography.Title>
          <Collapse>
            <Collapse.Panel key="slide1" header="Slide 1" forceRender={true}>
              <Form.Item name={["story", "quickQuoteText"]} label="Text">
                <Input.TextArea style={{ minHeight: "160px" }} />
              </Form.Item>

              <Form.Item name={["story", "imageUrl"]} label="Background Image">
                <UploadS3File fileType="STORY_IMAGE" type="picture" />
              </Form.Item>
            </Collapse.Panel>

            <Collapse.Panel key="slide2" header="Slide 2" forceRender={true}>
              <Form.Item name={["story", "detailText"]} label="Text">
                <Input.TextArea style={{ minHeight: "160px" }} />
              </Form.Item>

              <Form.Item
                name={["story", "slide2bgImageURL"]}
                label="Background Image"
              >
                <UploadS3File type="picture" fileType="SLIDE_2_BG_IMAGE_URL" />
              </Form.Item>
            </Collapse.Panel>

            <Collapse.Panel key="slide3" header="Slide 3" forceRender={true}>
              <Form.Item name={["story", "actionText"]} label="Text">
                <Input style={{ maxWidth: "360px" }} />
              </Form.Item>

              <Form.Item name={["story", "actionButtonUrl"]} label="Button URL">
                <Input style={{ maxWidth: "360px" }} />
              </Form.Item>

              <Form.Item
                name={["story", "actionImageUrl"]}
                label="Background Image"
              >
                <UploadS3File fileType="RAY_PHONE_IMAGE" type="picture" />
              </Form.Item>

              <Form.Item
                name={["story", "actionButtonText"]}
                label="Button Text"
              >
                <Input style={{ maxWidth: "360px" }} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>

      <Form.Item>
        <Button htmlType="submit" type="primary" loading={loading}>
          {submitText ?? "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
}

import {
  BreadcrumbItem,
  PageBreadcrumbs,
} from "../../components/PageBreadcrumbs";

import { CategoryType } from "../../types";

interface CategoryPageBreadCrumbsProps {
  category?: CategoryType;
  extra?: BreadcrumbItem[];
}

export function CategoryPageBreadcrumbs(props: CategoryPageBreadCrumbsProps) {
  let rootItem: BreadcrumbItem = { name: "Categories" };

  if (props.category) {
    rootItem.to = "/categories";
  }

  let items: BreadcrumbItem[] = [rootItem];

  if (props.category?.parentCategory) {
    const parent = props.category?.parentCategory;

    items.push({
      name: parent.name,
      to: `/categories/${parent.id}/subcategories`,
    });
  }

  items = items.concat(props.extra ?? []);

  return <PageBreadcrumbs items={items} />;
}

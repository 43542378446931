import { usePageRecord } from "../../components/PageLoader";
import { CategoryType, RaylistType } from "../../types";
import { Button, PageHeader, Popconfirm } from "antd";
import { RaylistTable } from "../ray-list/RaylistTable";
import { CloseOutlined } from "@ant-design/icons";
import {
  parseListResponse,
  RecordsTableApi,
  RecordsTableFetchParams,
  recordsToFetchParams,
} from "../../providers/RecordsProvider";
import { useSession } from "../../providers/SessionProvider";
import { useRef, useState } from "react";
import { CategoryRaylistMappingModal } from "./CategoryRaylistMappingModal";
import { useHistory } from "react-router-dom";
import { CategoryPageBreadcrumbs } from "./CategoryPageBreadcrumbs";

export function CategoryRayListsPage() {
  const { record: category } = usePageRecord<CategoryType>();
  const { http } = useSession();
  const history = useHistory();
  const [mappingModalVisible, setMappingModalVisible] = useState(false);
  const apiRef = useRef<RecordsTableApi<RaylistType> | undefined>(undefined);

  async function unlinkRaylist(raylist: RaylistType) {
    await http.delete(
      `/category-ray-lists/category/${category.id}/ray-list/${raylist.id}`
    );

    apiRef.current?.refresh();
  }

  const actions = (raylist: RaylistType) => (
    <Popconfirm
      title="Would you like to delete a Raylist?"
      onConfirm={() => unlinkRaylist(raylist)}
      okText="Yes, Delete"
      cancelText="No"
      placement="topRight"
      okButtonProps={{ danger: true }}
    >
      <CloseOutlined />
    </Popconfirm>
  );

  async function fetchCategoryRaylists(params: RecordsTableFetchParams) {
    const resp = await http.get(`/ray-list/rays/categories/${category.id}`, {
      params: recordsToFetchParams(params),
    });

    return parseListResponse<RaylistType>(resp);
  }

  function closeMappingModal() {
    setMappingModalVisible(false);
    apiRef.current?.refresh();
  }

  function pushToList() {
    if (category.parentCategory) {
      history.push(`/categories/${category.parentCategory.id}/subcategories`);
    } else {
      history.push("/categories");
    }
  }

  return (
    <PageHeader
      title="Raylists"
      onBack={() => pushToList()}
      breadcrumbRender={() => (
        <CategoryPageBreadcrumbs
          category={category}
          extra={[{ name: category.name }]}
        />
      )}
      extra={
        <Button type="primary" onClick={() => setMappingModalVisible(true)}>
          Add a Raylist
        </Button>
      }
    >
      {mappingModalVisible && (
        <CategoryRaylistMappingModal
          category={category}
          visible={mappingModalVisible}
          onCancel={closeMappingModal}
        />
      )}

      <RaylistTable
        apiRef={apiRef}
        actions={actions}
        resource={`${category.id}.raylists`}
        fetchRecords={fetchCategoryRaylists}
      />
    </PageHeader>
  );
}

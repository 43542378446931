import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Button, Checkbox, Dropdown, Menu, Tooltip } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useRecords } from "../providers/RecordsProvider";

export function SelectTableColumns() {
  const [visible, setVisible] = useState(false);
  const [records, recordsApi] = useRecords();

  const { hiddenColumns, columns } = records;

  const menu = useMemo(() => {
    const columnKey = (col: any) => _.get(col, "key", col.dataIndex);

    function toggleMenuItem(column: string) {
      if (hiddenColumns.includes(column)) {
        recordsApi.setHiddenColumns(_.without(hiddenColumns, column));
      } else {
        recordsApi.setHiddenColumns([...hiddenColumns, column]);
      }
    }

    return (
      <Menu>
        {columns.map((column) => {
          const key = columnKey(column);

          if (!key) {
            console.warn("Missing column key for", column.title);
          }

          if (!column.title) {
            return null;
          }

          return (
            <Menu.Item key={key}>
              <Checkbox
                checked={!hiddenColumns.includes(key)}
                onChange={() => toggleMenuItem(key)}
                style={{ width: "100%" }}
              >
                {column.title}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [columns, recordsApi, hiddenColumns]);

  return (
    <Tooltip title="Table Settings">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        visible={visible}
        placement="bottomRight"
        arrow
        onVisibleChange={(flag) => setVisible(flag)}
      >
        <Button icon={<SettingOutlined />} />
      </Dropdown>
    </Tooltip>
  );
}

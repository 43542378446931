import React from "react";
import { usePageRecord } from "../../components/PageLoader";
import { DeviceType } from "../../types";
import {
  Card,
  Col,
  Descriptions,
  PageHeader,
  Row,
  // Switch,
  Tag,
  Typography,
} from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { useHistory } from "react-router-dom";
import {
  DeviceFWVersion,
  DeviceOnlineStatus,
  DeviceState,
  DeviceStatusFWVersion,
  DeviceWifiSignal,
} from "./DevicesComponents";
import moment from "moment";
import { DevicesUpdateSettings } from "./DevicesUpdateSettings";

export function DevicesDetailsPage() {
  const history = useHistory();
  const { record: device, loadRecord } = usePageRecord<DeviceType>();

  return (
    <PageHeader
      title={device.serial}
      onBack={() => history.push("/devices")}
      tags={[<DeviceOnlineStatus status={device.status} />]}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[{ name: "Devices", to: "/devices" }, { name: device.serial }]}
        />
      )}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Card
            title="Device"
            size="small"
            bodyStyle={{ padding: 0 }}
            style={{ maxWidth: 800 }}
          >
            <Descriptions column={1} bordered>
              <Descriptions.Item label="ID">
                <Tag>
                  <Typography.Text copyable>{device.id}</Typography.Text>
                </Tag>
              </Descriptions.Item>

              <Descriptions.Item label="Serial">
                <Tag>
                  <Typography.Text copyable>{device.serial}</Typography.Text>
                </Tag>
              </Descriptions.Item>

              <Descriptions.Item label="State">
                <DeviceState state={device.state} />
              </Descriptions.Item>

              <Descriptions.Item label="H/W Version">
                <Tag>{device.hwVersion}</Tag>
              </Descriptions.Item>

              <Descriptions.Item label="OTA F/W Version">
                <DeviceFWVersion device={device} />
              </Descriptions.Item>

              <Descriptions.Item label="F/W Version">
                <DeviceStatusFWVersion device={device} />
              </Descriptions.Item>

              <Descriptions.Item label="OS Version">
                <Tag>{device.osVersionName}</Tag>
              </Descriptions.Item>

              <Descriptions.Item label="Wifi">
                <DeviceWifiSignal status={device.status} withTitle />
              </Descriptions.Item>

              <Descriptions.Item label="Last Seen">
                <Typography.Text>
                  {device.status?.lastHbTime ? (
                    moment(device.status.lastHbTime).format("lll")
                  ) : (
                    <Typography.Text type="secondary">---</Typography.Text>
                  )}
                </Typography.Text>
              </Descriptions.Item>

              <Descriptions.Item label="Timezone">
                {device.status?.timeZone ? (
                  <Typography.Text>{device.status.timeZone}</Typography.Text>
                ) : (
                  <Typography.Text type="secondary">---</Typography.Text>
                )}
              </Descriptions.Item>

              <Descriptions.Item label="Created">
                <Typography.Text>
                  {moment(device.createTime).format("lll")}
                </Typography.Text>
              </Descriptions.Item>

              <Descriptions.Item label="Modified">
                <Typography.Text>
                  {moment(device.modifyTime).format("lll")}
                </Typography.Text>
              </Descriptions.Item>
              {!!device.users?.length && (
                <Descriptions.Item label="Linked Account">
                  <Typography.Text>
                    {device.users.map((user) => user.email)}
                  </Typography.Text>
                </Descriptions.Item>
              )}
			  <Descriptions.Item label="Current Ray ID">
                <Tag>
                {device.currentlyRaying?.rayId ? (
                     <Typography.Link href={'/rays/' + device.currentlyRaying.rayId + '/update'} copyable>{device.currentlyRaying.rayId}</Typography.Link>
                  ) : (
                    <Typography.Text type="secondary">---</Typography.Text>
                  )}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Current Raylist ID">
                <Tag>
                  {device.currentlyRaying?.rayListId ? (
                    <Typography.Link copyable href = {"/raylists/" + device.currentlyRaying.rayListId +"/details"}>{device.currentlyRaying.rayListId}</Typography.Link>
                  ) : (
                    <Typography.Text type="secondary">---</Typography.Text>
                  )}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={12}>
          <DevicesUpdateSettings device={device} loadRecord={loadRecord} />
        </Col>
      </Row>
    </PageHeader>
  );
}

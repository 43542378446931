import React from "react";
import { RayStoryType } from "../../types";
import { Space, Typography } from "antd";
import { ImagePreview } from "../../components/ImagePreview";

interface RayStoryTableCellProps {
  story: RayStoryType;
}

export function RayStoryTableCell(props: RayStoryTableCellProps) {
  const { story } = props;

  if (!story) {
    return <Typography.Text type="secondary">---</Typography.Text>;
  }

  return (
    <Space direction="horizontal" size={10}>
      <ImagePreview src={story.imageUrl} alt={story.quickQuoteText} />
      <div>
        <Typography.Text>{story.quickQuoteText}</Typography.Text>
        <br />
        <Typography.Text
          type="secondary"
          ellipsis={{ tooltip: story.detailText }}
        >
          {story.detailText || "---"}
        </Typography.Text>
      </div>
    </Space>
  );
}

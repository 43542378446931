import { Avatar, List, ModalProps } from "antd";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { ArchetypeType, PersonType } from "../../types";
import _ from "lodash";
import update from "react-addons-update";
import { RecordsMappingModal } from "../../components/RecordsMappingModal";

interface ArchetypePersonMappingModalProps extends ModalProps {
  archetype: ArchetypeType;
}

export function ArchetypePersonMappingModal(
  props: ArchetypePersonMappingModalProps
) {
  const { archetype, ...modalPros } = props;
  const { http } = useSession();

  const handleSearch = useCallback(
    async (term: string) => {
      const resp = await http.get(`/persons`, {
        params: {
          ...(term.length > 0 && {
            search: term,
          }),
          size: 5,
          withArchetypes: true,
        },
      });

      return resp.data.content;
    },
    [http]
  );

  const linkPerson = useCallback(
    async (person: PersonType) => {
      await http.post(`/archetypes/${archetype.id}/person/${person.id}`);
    },
    [http, archetype.id]
  );

  function linkPersonUpdateState(persons: PersonType[], person: PersonType) {
    const index = _.findIndex(persons, { id: person.id });

    if (!persons[index].archetypes) {
      persons[index].archetypes = [];
    }

    return update(persons, {
      [index]: {
        archetypes: {
          $push: [archetype],
        },
      },
    });
  }

  const unlinkPerson = useCallback(
    async (person: PersonType) => {
      await http.delete(`/archetypes/${archetype.id}/person/${person.id}`);
    },
    [http, archetype.id]
  );

  function unlinkPersonUpdateState(persons: PersonType[], person: PersonType) {
    const index = _.findIndex(persons, { id: person.id });
    const categoryIndex = _.findIndex(persons?.[index].archetypes, {
      id: archetype.id,
    });

    return update(persons, {
      [index]: {
        archetypes: {
          $splice: [[categoryIndex, 1]],
        },
      },
    });
  }

  function renderPersonItem(person: PersonType, button: React.ReactNode) {
    const name = [person.firstName, person.lastName]
      .filter(_.identity)
      .join(" ");

    return (
      <List.Item>
        <Avatar src={person.avatarUrl} />

        <List.Item.Meta
          style={{ marginLeft: 10 }}
          title={name}
          description={person.profession}
        />

        {button}
      </List.Item>
    );
  }

  function isPersonLinked(person: PersonType) {
    const { archetypes = [] } = person;
    return archetypes.findIndex((next) => next.id === archetype.id) !== -1;
  }

  return (
    <RecordsMappingModal<PersonType>
      title="Add a Person"
      {...modalPros}
      search={handleSearch}
      isRecordLinked={isPersonLinked}
      renderItem={renderPersonItem}
      linkRecord={linkPerson}
      linkRecordUpdateState={linkPersonUpdateState}
      unlinkRecord={unlinkPerson}
      unlinkRecordUpdateState={unlinkPersonUpdateState}
    />
  );
}

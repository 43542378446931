import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RecordRoute } from "./components/router/RecordRoute";

import { PersonListPage } from "./resources/person/PersonListPage";

import { RayListPage } from "./resources/ray/RayListPage";
import { RayCreatePage } from "./resources/ray/RayCreatePage";
import { RayUpdatePage } from "./resources/ray/RayUpdatePage";
import { RayPageLoader } from "./resources/ray/useRayLoader";

import { RaylistListPage } from "./resources/ray-list/RaylistListPage";
import { RaylistCreatePage } from "./resources/ray-list/RaylistCreatePage";
import { RaylistUpdatePage } from "./resources/ray-list/RaylistUpdatePage";
import { RaylistPageLoader } from "./resources/ray-list/useRaylistLoader";
import { RaylistDetailsPage } from "./resources/ray-list/RaylistDetailsPage";

import { CategoryListPage } from "./resources/category/CategoryListPage";
import { CategoryPageLoader } from "./resources/category/useCategroyLoader";
import { CategorySubcategoriesListPage } from "./resources/category/CategorySubcategoriesListPage";
import { CategoryRayListsPage } from "./resources/category/CategoryRayListsPage";
import { CategoryCreatePage } from "./resources/category/CategoryCreatePage";
import { CategoryEditPage } from "./resources/category/CategoryEditPage";

import { ArchetypeListPage } from "./resources/archetype/ArchetypeListPage";
import { ArchetypeCreatePage } from "./resources/archetype/ArchetypeCreatePage";
import { ArchetypePageLoader } from "./resources/archetype/useArchetypeLoader";
import { ArchetypeUpdatePage } from "./resources/archetype/ArchetypeEditPage";
import { ArchetypeDetailsPage } from "./resources/archetype/ArchetypeDetailsPage";

import { UsersListPage } from "./resources/users/UsersListPage";

import { DevicesListPage } from "./resources/devices/DevicesListPage";
import { DevicesDetailsPage } from "./resources/devices/DevicesDetailsPage";
import { DevicePageLoader } from "./resources/devices/useDeviceLoader";

export function AppRouter() {
  return (
    <Switch>
      <Route path="/persons" exact component={PersonListPage} />

      <Route path="/rays" exact component={RayListPage} />
      <Route path="/rays/create" exact component={RayCreatePage} />

      <RecordRoute
        path="/rays/:rayId/update"
        exact
        loader={RayPageLoader}
        component={RayUpdatePage}
      />

      <Route path="/raylists" exact component={RaylistListPage} />
      <Route path="/raylists/create" exact component={RaylistCreatePage} />

      <RecordRoute
        path="/raylists/:raylistId/update"
        exact
        loader={RaylistPageLoader}
        component={RaylistUpdatePage}
      />

      <RecordRoute
        path="/raylists/:raylistId/details"
        exact
        loader={RaylistPageLoader}
        component={RaylistDetailsPage}
      />

      <Route path="/categories" exact component={CategoryListPage} />

      <Route path="/categories/create" exact component={CategoryCreatePage} />

      <RecordRoute
        path="/categories/:categoryId/edit"
        exact
        loader={CategoryPageLoader}
        component={CategoryEditPage}
      />

      <RecordRoute
        path="/categories/:categoryId/subcategories"
        exact
        loader={CategoryPageLoader}
        component={CategorySubcategoriesListPage}
      />

      <RecordRoute
        path="/categories/:categoryId/raylists"
        exact
        loader={CategoryPageLoader}
        component={CategoryRayListsPage}
      />

      <Route path="/archetypes" exact component={ArchetypeListPage} />
      <Route path="/archetypes/create" exact component={ArchetypeCreatePage} />

      <RecordRoute
        path="/archetypes/:archetypeId/edit"
        loader={ArchetypePageLoader}
        exact
        component={ArchetypeUpdatePage}
      />

      <RecordRoute
        path="/archetypes/:archetypeId/details/:tab"
        loader={ArchetypePageLoader}
        exact
        component={ArchetypeDetailsPage}
      />

      <Route path="/users" exact component={UsersListPage} />
      <Route path="/devices" exact component={DevicesListPage} />

      <RecordRoute
        path="/devices/:deviceId/details"
        loader={DevicePageLoader}
        exact
        component={DevicesDetailsPage}
      />

      <Route path="/" exact>
        <Redirect to="/persons" />
      </Route>
    </Switch>
  );
}

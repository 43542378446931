import { Button, ButtonProps, message } from "antd";
import { useState } from "react";

interface ProcessButtonProps<Type> extends ButtonProps {
  action(item: Type): Promise<any>;
  item: Type;
}

export function ProcessButton<Type>(props: ProcessButtonProps<Type>) {
  const { action, item, ...buttonProps } = props;
  const [loading, setLoading] = useState(false);

  async function handleAction() {
    setLoading(true);
    try {
      await action(item);
      setLoading(false);
    } catch (ex) {
      message.error("Could not complete operation.");
      setLoading(false);
    }
  }

  return <Button onClick={handleAction} loading={loading} {...buttonProps} />;
}

import React from "react";
import { Col, Form, FormProps, Input, Row, Switch, Select } from "antd";
import _ from "lodash";
import { PersonSelectField } from "../person/PersonSelectField";
import { UploadS3File } from "../../components/Form/UploadS3File";
import { RaylistType } from "../../types";

interface RaylistFormProps extends FormProps {
  raylist?: RaylistType;
}

export function RaylistForm(props: RaylistFormProps) {
  const { children, raylist, ...formProps } = props;

  const initialValues = {
    ..._.omit(raylist, "curator"),
  };

  const raylistTypeOptions = [
    { label: "Free", value: "FREE" },
    { label: "Premium", value: "PREMIUM" },
    { label: "One-Time Purchase", value: "ONE_TIME_PURCHASE" },
  ];

  return (
    <Form layout="vertical" initialValues={initialValues} {...formProps}>
      <Form.Item
        name="caption"
        label="Caption"
        rules={[{ required: true }, { type: "string", max: 1000 }]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ type: "string", max: 1000 }]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="curatorId" label="Curator" rules={[{ required: true }]}>
        <PersonSelectField selectedPerson={raylist?.curator} />
      </Form.Item>

      <Row>
        <Col span={12}>
          <Form.Item
            name="imageDetailUrl"
            label="Detail Image"
            rules={[{ required: true }]}
          >
            <UploadS3File fileType="RAY_LIST_IMAGE_DETAIL" type="picture" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="imageCardUrl"
            label="Card Image"
            rules={[{ required: true }]}
          >
            <UploadS3File fileType="RAY_LIST_IMAGE_CARD" type="picture" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item name="infinite" label="Infinite" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select
              options={raylistTypeOptions}
              defaultValue={raylistTypeOptions[0].value}
              value={raylist?.type}
            />
          </Form.Item>
        </Col>
      </Row>

      {children}
    </Form>
  );
}

import React, { useState } from "react";
import { Button, PageHeader, message, Row, Col } from "antd";
import { CategoryForm } from "./CategoryForm";
import { useSession } from "../../providers/SessionProvider";
import { useHistory, useLocation } from "react-router-dom";
import { CategoryPageBreadcrumbs } from "./CategoryPageBreadcrumbs";

export function CategoryCreatePage() {
  const [loading, setLoading] = useState(false);
  const { http } = useSession();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search.replace("?", ""));
  const parentCategoryId = searchParams.get("parentId") || null;

  function pushToList() {
    if (parentCategoryId) {
      history.push(`/categories/${parentCategoryId}/subcategories`);
    } else {
      history.push("/categories");
    }
  }

  async function createCategory(payload: any) {
    setLoading(true);
    try {
      await http.post(`/categories`, {
        ...payload,
        parentCategoryId,
      });
      setLoading(false);
      pushToList();
    } catch (ex) {
      setLoading(false);
      message.error(ex.message);
    }
  }

  return (
    <PageHeader
      title="Create a Category"
      onBack={() => pushToList()}
      breadcrumbRender={() => (
        <CategoryPageBreadcrumbs extra={[{ name: "Create a Category" }]} />
      )}
    >
      <Row>
        <Col span={12}>
          <CategoryForm onFinish={createCategory}>
            <Button type="primary" loading={loading} htmlType="submit">
              Create
            </Button>
          </CategoryForm>
        </Col>
      </Row>
    </PageHeader>
  );
}

import { Empty, Select, Spin } from "antd";
import { SelectProps } from "antd/es/select";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

export interface SelectRecordProps
  extends Omit<
    SelectProps<any>,
    "notFoundContent" | "loading" | "options" | "onSearch"
  > {
  emptyDescriptionText?: string;
  debounceWait?: number;
  searchRecords(term: string | null): Promise<SelectProps<any>["options"]>;
  fetchRecord(value: string): any;
}

export function SelectRecord(props: SelectRecordProps) {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [emptyOptions, setEmptyOptions] = useState<SelectProps<any>["options"]>(
    []
  );

  const [options, setOptions] = useState<SelectProps<any>["options"]>([]);

  const {
    emptyDescriptionText = "No Records",
    debounceWait = 500,
    searchRecords,
    fetchRecord,
    value,
    ...selectProps
  } = props;

  const loadEmptyOptions = useCallback(async () => {
    setLoading(true);
    let valueOption = undefined;

    if (value) {
      valueOption = await fetchRecord(value);
    }

    const options = (await searchRecords(null)) || [];
    setEmptyOptions(valueOption ? [valueOption, ...options] : options);
    setSearchTerm("");
    setLoading(false);
  }, [value, fetchRecord, searchRecords]);

  useEffect(() => {
    const handler = _.debounce(async (term: string | null) => {
      const search = term ? term.replace(/^\s+|['"$%*]/g, "") : null;

      setLoading(true);

      try {
        const options = await searchRecords(search);
        setOptions(options);
        setLoading(false);
      } catch (ex) {
        setLoading(false);
      }
    }, debounceWait);

    handler(searchTerm);
    return () => {
      handler.cancel();
    };
  }, [searchTerm, searchRecords, debounceWait]);

  // Fetch initial value by id
  useEffect(() => {
    if (value && _.isEmpty(emptyOptions)) {
      loadEmptyOptions();
    }
  }, [loadEmptyOptions, value, emptyOptions]);

  if (value && _.isEmpty(emptyOptions)) {
    return <Select {...selectProps} disabled value="Loading..." />;
  }

  const displayOptions = _.isEmpty(searchTerm) ? emptyOptions : options;

  return (
    <Select
      options={displayOptions}
      filterOption={false}
      showSearch={true}
      clearIcon={true}
      loading={loading}
      onFocus={() => _.isEmpty(displayOptions) && loadEmptyOptions()}
      onSearch={(term: string) => setSearchTerm(term)}
      value={value}
      notFoundContent={
        searchTerm === null || loading ? (
          <Spin size="small" />
        ) : (
          <Empty
            description={emptyDescriptionText}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      }
      {...selectProps}
    />
  );
}

import { List, ModalProps } from "antd";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { ArchetypeType, RaylistType } from "../../types";
import { ImagePreview } from "../../components/ImagePreview";
import _ from "lodash";
import update from "react-addons-update";
import { RecordsMappingModal } from "../../components/RecordsMappingModal";

interface ArchetypeFeatureRaylistMappingModalProps extends ModalProps {
  archetype: ArchetypeType;
}

export function ArchetypeFeatureRaylistMappingModal(
  props: ArchetypeFeatureRaylistMappingModalProps
) {
  const { archetype, ...modalPros } = props;
  const { http } = useSession();

  const handleSearch = useCallback(
    async (term: string) => {
      const resp = await http.get(`/ray-list`, {
        params: {
          ...(term.length > 0 && {
            search: term,
          }),
          size: 5,
          withFeatureArchetypes: true,
        },
      });

      return resp.data.content;
    },
    [http]
  );

  const linkFeatureRaylist = useCallback(
    async (raylist: RaylistType) => {
      await http.post(
        `/archetypes/${archetype.id}/feature/ray-list/${raylist.id}`
      );
    },
    [http, archetype.id]
  );

  function linkFeatureRaylistUpdateState(
    raylists: RaylistType[],
    raylist: RaylistType
  ) {
    const index = _.findIndex(raylists, { id: raylist.id });

    if (!raylists[index].featureArchetypes) {
      raylists[index].featureArchetypes = [];
    }

    return update(raylists, {
      [index]: {
        featureArchetypes: {
          $push: [archetype],
        },
      },
    });
  }

  const unlinkFeatureRaylist = useCallback(
    async (raylist: RaylistType) => {
      await http.delete(
        `/archetypes/${archetype.id}/feature/ray-list/${raylist.id}`
      );
    },
    [http, archetype.id]
  );

  function unlinkFeatureRaylistUpdateState(
    raylists: RaylistType[],
    raylist: RaylistType
  ) {
    const index = _.findIndex(raylists, { id: raylist.id });
    const categoryIndex = _.findIndex(raylists?.[index].featureArchetypes, {
      id: archetype.id,
    });

    return update(raylists, {
      [index]: {
        featureArchetypes: {
          $splice: [[categoryIndex, 1]],
        },
      },
    });
  }

  function renderRaylistItem(raylist: RaylistType, button: React.ReactNode) {
    return (
      <List.Item>
        <ImagePreview alt={raylist.caption} src={raylist.imageCardUrl} />

        <List.Item.Meta
          style={{ marginLeft: 10 }}
          title={raylist.caption}
          description={`${raylist.curator.firstName} ${raylist.curator.lastName}`}
        />

        {button}
      </List.Item>
    );
  }

  function isFeatureRaylistLinked(raylist: RaylistType) {
    const { featureArchetypes = [] } = raylist;
    return (
      featureArchetypes.findIndex((next) => next.id === archetype.id) !== -1
    );
  }

  return (
    <RecordsMappingModal<RaylistType>
      title="Add a Featured Raylist"
      {...modalPros}
      search={handleSearch}
      isRecordLinked={isFeatureRaylistLinked}
      renderItem={renderRaylistItem}
      linkRecord={linkFeatureRaylist}
      linkRecordUpdateState={linkFeatureRaylistUpdateState}
      unlinkRecord={unlinkFeatureRaylist}
      unlinkRecordUpdateState={unlinkFeatureRaylistUpdateState}
    />
  );
}

import React, { useState } from "react";
import { Button, Col, message, PageHeader, Popconfirm, Row } from "antd";
import { PageBreadcrumbs } from "../../components/PageBreadcrumbs";
import { ArchetypeForm } from "./ArchetypeForm";
import { useSession } from "../../providers/SessionProvider";
import { useHistory } from "react-router-dom";
import { usePageRecord } from "../../components/PageLoader";
import { ArchetypeType } from "../../types";

export function ArchetypeUpdatePage() {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { http } = useSession();
  const history = useHistory();

  const { record: archetype } = usePageRecord<ArchetypeType>();

  async function handleSubmitForm(payload: any) {
    try {
      setLoading(true);
      await http.put(`/archetypes/${archetype.id}`, payload);
      setLoading(false);
      message.success("Archetype successfully updated.");
      history.push("/archetypes");
    } catch (ex) {
      setLoading(false);
      message.error("Could not update an archetype.");
    }
  }

  async function handleDeleteArchetype() {
    setDeleteLoading(true);

    try {
      setLoading(true);
      await http.delete(`/archetypes/${archetype.id}`);
      setLoading(false);
      message.success("Archetype successfully deleted.");
      history.push("/archetypes");
    } catch (ex) {
      setLoading(false);
      message.error("Could not delete an archetype.");
    }
  }

  return (
    <PageHeader
      title="Update an archetype"
      subTitle={archetype.id}
      onBack={() => history.push("/archetypes")}
      breadcrumbRender={() => (
        <PageBreadcrumbs
          items={[
            { name: "Archetypes", to: "/archetypes" },
            { name: "Update an archetype" },
          ]}
        />
      )}
      extra={
        <Popconfirm
          title="Do you really want to delete a category?"
          placement="bottomRight"
          okText="Yes, Delete"
          okButtonProps={{ danger: true }}
          onConfirm={handleDeleteArchetype}
        >
          <Button danger loading={deleteLoading}>
            Delete
          </Button>
        </Popconfirm>
      }
    >
      <Row>
        <Col span={12}>
          <ArchetypeForm onFinish={handleSubmitForm} initialValues={archetype}>
            <Button htmlType="submit" type="primary" loading={loading}>
              Update
            </Button>
          </ArchetypeForm>
        </Col>
      </Row>
    </PageHeader>
  );
}

import { useRecordLoader } from "../../components/useRecordLoader";
import { RayType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { PageLoader } from "../../components/PageLoader";

export function useRayLoader() {
  const { http } = useSession();

  const fetchRay = useCallback(
    async (id: string) => {
      const resp = await http.get(`/rays/${id}`);
      return resp.data;
    },
    [http]
  );

  return useRecordLoader<RayType>({
    fetchRecord: fetchRay,
    idParam: "rayId",
    recordStateProp: "ray",
  });
}

export function RayPageLoader(props: any) {
  return <PageLoader useRecordLoader={useRayLoader} {...props} />;
}

import React, {
  Reducer,
  useMemo,
  useReducer,
  createContext,
  useContext,
  ReactNode,
  MutableRefObject,
  useEffect,
} from "react";

import { ColumnType } from "antd/es/table";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { useQueryParams } from "../hooks/useQueryParams";
import { useHistory } from "react-router-dom";

export type RecordsTableFilters = Record<string, any>;

export interface RecordsTableSorting {
  order?: "asc" | "desc";
  field: string;
}

export interface RecordsTablePagination {
  current?: number;
  total?: number;
  pageSize?: number;
  showSizeChanger?: boolean;
}

export interface RecordsTableState<RecordType> {
  resource: string;
  columns: ColumnType<RecordType>[];
  selectedRecords: RecordType[];
  hiddenColumns: string[];
  records: RecordType[];
  pagination: RecordsTablePagination;
  sorting: RecordsTableSorting[];
  filters?: RecordsTableFilters;
  loading: boolean;
  backgroundLoading: boolean;
  initial: boolean;
  error?: Error;
}

export interface RecordsTableFetchResponse<RecordType> {
  records: RecordType[];
  pagination: RecordsTablePagination;
}

export interface RecordsTableFetchParams {
  pagination: RecordsTablePagination;
  sorting: RecordsTableSorting[];
  filters?: RecordsTableFilters;
}

export interface RecordsTableApi<RecordType> {
  fetch(
    params: RecordsTableFetchParams,
    background?: boolean
  ): Promise<RecordsTableFetchResponse<RecordType> | null>;
  refresh(
    background?: boolean
  ): Promise<RecordsTableFetchResponse<RecordType> | null>;
  setPagination(
    pagination: RecordsTablePagination
  ): Promise<RecordsTableFetchResponse<RecordType> | null>;
  setSorting(
    sorting: RecordsTableSorting[]
  ): Promise<RecordsTableFetchResponse<RecordType> | null>;
  setFilter(
    name: string,
    value: string
  ): Promise<RecordsTableFetchResponse<RecordType> | null>;
  removeFilter(
    name: string
  ): Promise<RecordsTableFetchResponse<RecordType> | null>;
  setFilters(
    filters: RecordsTableFilters
  ): Promise<RecordsTableFetchResponse<RecordType> | null>;
  setHiddenColumns(hiddenColumns: string[]): void;
  setSelectedRecords(records: RecordType[]): void;
}

export interface RecordsProviderProps<RecordType> {
  apiRef?: MutableRefObject<RecordsTableApi<RecordType> | undefined>;
  resource: string;
  columns: ColumnType<RecordType>[];
  hiddenColumns?: string[];
  fetchRecords(
    params: RecordsTableFetchParams
  ): Promise<RecordsTableFetchResponse<RecordType>>;
  children?: ReactNode;
  initialPageSize?: number;
  autoRefresh?: number;
}

type Action<T> =
  | { type: "loading"; loading?: boolean; backgroundLoading?: boolean }
  | {
      type: "data";
      response: RecordsTableFetchResponse<T>;
      params: RecordsTableFetchParams;
    }
  | { type: "set-hidden-columns"; columns: string[] }
  | { type: "set-selected-records"; records: T[] }
  | { type: "error"; error: Error };

function reducer<S>(
  state: RecordsTableState<S>,
  action: Action<S>
): RecordsTableState<S> {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: action.loading ?? state.loading,
        backgroundLoading: action.backgroundLoading ?? state.backgroundLoading,
      };

    case "data":
      return {
        ...state,
        loading: false,
        backgroundLoading: false,
        initial: false,
        error: undefined,
        records: action.response.records,
        pagination: action.response.pagination,
        sorting: action.params.sorting,
        filters: action.params.filters,
      };

    case "set-hidden-columns":
      return {
        ...state,
        hiddenColumns: action.columns,
      };

    case "set-selected-records":
      return {
        ...state,
        selectedRecords: action.records,
      };

    case "error":
      return {
        ...state,
        loading: false,
        backgroundLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export type RecordsContextType<RecordType> = [
  RecordsTableState<RecordType>,
  RecordsTableApi<RecordType>
];

export const RecordsContext = createContext<
  RecordsContextType<any> | undefined
>(undefined);
export const RecordsConsumer = RecordsContext.Consumer;

export function useRecords<RecordType>(): RecordsContextType<RecordType> {
  const context = useContext(RecordsContext);

  if (context === undefined) {
    throw new Error("useRecords must be used inside RecordsProvider");
  }

  return context;
}

export function recordsToFetchParams(params: RecordsTableFetchParams) {
  const sort = _.first(params.sorting);
  const page = params.pagination.current ?? 1;

  return {
    ...(!!params.pagination && {
      page: page - 1,
      size: params.pagination.pageSize,
    }),

    // ...(!_.isEmpty(sort) &&
    //   sort?.order && {
    //     orderBy: sort.field,
    //     orderDirection: _.upperCase(sort.order),
    //   }),
    ...(!_.isEmpty(sort) &&
      sort?.order && {
        sort: `${_.upperCase(sort.order)}:${sort.field}`,
      }),

    ...(!_.isEmpty(params.filters) && params.filters),
  };
}

export function parseListResponse<RecordType>(
  response: AxiosResponse
): RecordsTableFetchResponse<RecordType> {
  const { content: records, totalElements, size, number } = response.data;

  return {
    records,
    pagination: {
      current: number + 1,
      pageSize: size,
      total: totalElements,
    },
  };
}

const recordsCache: Record<string, any[]> = {};

export function RecordsProvider<RecordType>(
  props: RecordsProviderProps<RecordType>
) {
  const { Provider } = RecordsContext;
  const history = useHistory();
  const queryParams = useQueryParams();
  const {
    fetchRecords,
    columns,
    hiddenColumns = [],
    apiRef,
    autoRefresh,
    initialPageSize = 10,
    ...restProps
  } = props;

  const records = _.get(recordsCache, props.resource, []);

  const queryPage = queryParams.get("page")
    ? Number(queryParams.get("page"))
    : 1;
  const querySortedField = queryParams.get("field")
    ? queryParams.get("field")
    : null;
  const querySortedOrder: any = queryParams.get("order")
    ? queryParams.get("order")
    : null;
  const queryPageSize = queryParams.get("pageSize")
    ? Number(queryParams.get("pageSize"))
    : initialPageSize;

  const initialData: RecordsTableState<RecordType> = {
    resource: props.resource,
    columns,
    hiddenColumns,
    selectedRecords: [],
    records,
    sorting: [
      {
        field: querySortedField ? querySortedField : "",
        order: querySortedOrder ? querySortedOrder : "",
      },
    ],
    pagination: {
      current: queryPage,
      pageSize: queryPageSize,
      showSizeChanger: true,
    },
    loading: false,
    backgroundLoading: false,
    initial: records.length === 0,
  };

  useEffect(() => {
    let timer: any | null = null;

    if (autoRefresh) {
      console.log("setInterval", autoRefresh);
      timer = setInterval(() => {
        apiRef?.current?.refresh(true);
      }, autoRefresh);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [autoRefresh, apiRef]);

  const [state, dispatch] = useReducer<
    Reducer<RecordsTableState<RecordType>, Action<RecordType>>
  >(reducer, initialData);

  const contextValue = useMemo<RecordsContextType<RecordType>>(() => {
    const api: RecordsTableApi<RecordType> = {
      async fetch(
        params: RecordsTableFetchParams,
        background: boolean = false
      ): Promise<RecordsTableFetchResponse<RecordType> | null> {
        dispatch({
          type: "loading",
          ...(background ? { backgroundLoading: true } : { loading: true }),
        });

        try {
          const response = await fetchRecords(params);
          recordsCache[props.resource] = response.records;
          dispatch({ type: "data", params, response });
          return response;
        } catch (ex) {
          dispatch({ type: "error", error: ex });
          return null;
        }
      },

      async refresh(
        background: boolean = false
      ): Promise<RecordsTableFetchResponse<RecordType> | null> {
        const params = {
          pagination: state.pagination,
          filters: state.filters,
          sorting: state.sorting,
        };

        return this.fetch(params, background);
      },

      async setPagination(
        pagination: RecordsTablePagination
      ): Promise<RecordsTableFetchResponse<RecordType> | null> {
        history.push(
          `?page=${pagination.current}&pageSize=${pagination.pageSize}`
        );
        const params = {
          pagination,
          filters: state.filters,
          sorting: state.sorting,
        };

        return this.fetch(params);
      },

      async setSorting(
        sorting: RecordsTableSorting[]
      ): Promise<RecordsTableFetchResponse<RecordType> | null> {
        const params = {
          pagination: {
            ...state.pagination,
            current: 1,
          },
          filters: state.filters,
          sorting,
        };

        const sortingField = !!sorting[0].order?.length
          ? `&field=${sorting[0].field}&order=${sorting[0].order}`
          : "";

        history.push(
          `?page=${state.pagination.current}&pageSize=${state.pagination.pageSize}${sortingField}`
        );

        return this.fetch(params);
      },

      async setFilter(
        name: string,
        value: string
      ): Promise<RecordsTableFetchResponse<RecordType> | null> {
        if (state.filters?.[name] !== value) {
          const filters = {
            ...state.filters,
            [name]: value,
          };

          return this.setFilters(filters);
        }

        return null;
      },

      async removeFilter(name: string) {
        if (state.filters?.[name]) {
          return this.setFilters(_.omit(state.filters, [name]));
        }

        return null;
      },

      async setFilters(
        filters: RecordsTableFilters
      ): Promise<RecordsTableFetchResponse<RecordType> | null> {
        const params = {
          pagination: {
            ...state.pagination,
            current: 1,
          },
          sorting: state.sorting,
          filters,
        };

        return this.fetch(params);
      },

      setHiddenColumns(hiddenColumns: string[]) {
        dispatch({ type: "set-hidden-columns", columns: hiddenColumns });
      },

      setSelectedRecords(records: RecordType[]) {
        dispatch({ type: "set-selected-records", records });
      },
    };

    return [state, api];
  }, [state, dispatch, fetchRecords, props.resource, history]);

  if (apiRef) {
    apiRef.current = contextValue[1];
  }

  return <Provider {...restProps} value={contextValue} />;
}

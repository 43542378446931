import React from "react";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useRecords } from "../providers/RecordsProvider";

export function RefreshButton() {
  const records = useRecords();
  const [state, api] = records;

  return (
    <Tooltip title="Refresh">
      <Button
        icon={
          state.backgroundLoading ? <LoadingOutlined /> : <ReloadOutlined />
        }
        disabled={state.backgroundLoading}
        onClick={() => api.refresh()}
        className="records-refresh"
      />
    </Tooltip>
  );
}

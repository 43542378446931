import { SelectRecord } from "../../components/Form/SelectRecord";
import { useSession } from "../../providers/SessionProvider";
import { RaylistType } from "../../types";
import { useCallback } from "react";
import { Col, Row, Space, Tag, Typography } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

interface RaylistSelectLabelProps {
  raylist: RaylistType;
}

function RaylistSelectLabel(props: RaylistSelectLabelProps) {
  const { raylist } = props;

  return (
    <Row align="middle">
      <Col flex={1}>
        <Space direction="vertical" size={0}>
          <Typography.Text>{raylist.caption}</Typography.Text>
          <i>
            <Typography.Text type="secondary">
              by {raylist.curator.firstName} {raylist.curator.firstName}
            </Typography.Text>
          </i>
        </Space>
      </Col>

      {raylist.infinite && (
        <Col>
          <Tag icon={<RetweetOutlined />} title="Infinite">
            Inf.
          </Tag>
        </Col>
      )}
    </Row>
  );
}

export function RaylistSelectRecord(props: any) {
  const { http } = useSession();

  function raylistToOption(raylist: RaylistType) {
    return {
      value: raylist.id,
      label: <RaylistSelectLabel raylist={raylist} />,
      text: raylist.caption,
    };
  }

  const searchRaylists = useCallback(
    async (term: string | null) => {
      const resp = await http.get("/ray-list", {
        params: {
          page: 0,
          size: 10,
          ...(term && { search: term }),
        },
      });

      const {
        data: { content },
      } = resp;

      return content.map(raylistToOption);
    },
    [http]
  );

  const fetchRaylistById = useCallback(
    async (raylistId: string) => {
      const resp = await http.get(`/ray-list/${raylistId}`);

      const { data: raylist } = resp;
      return raylistToOption(raylist);
    },
    [http]
  );

  return (
    <SelectRecord
      emptyDescriptionText="No Raylists"
      searchRecords={searchRaylists}
      fetchRecord={fetchRaylistById}
      optionLabelProp="text"
      {...props}
    />
  );
}

import {
  RecordsProvider,
  RecordsProviderProps,
} from "../../providers/RecordsProvider";
import { CategoryType, PersonType, RaylistType } from "../../types";
import { Space, Tag, Typography } from "antd";
import { ImagePreview } from "../../components/ImagePreview";
import { Link } from "react-router-dom";
import { PersonTableCell } from "../person/PersonTableCell";
import { RecordsTable } from "../../components/RecordsTable";
import {
  RecordsTableHeader,
  RecordsTableHeaderProps,
} from "../../components/RecordsTableHeader";

interface RaylistTableProps
  extends Omit<RecordsProviderProps<RaylistType>, "columns"> {
  actions(raylist: RaylistType): React.ReactNode;
  tableHeaderProps?: RecordsTableHeaderProps;
}

export function RaylistTable(props: RaylistTableProps) {
  const { actions, tableHeaderProps, ...otherProps } = props;

  const columns = [
    {
      title: "Caption",
      dataIndex: "caption",
      width: "25%",
      render: (caption: string, raylist: RaylistType) => (
        <Space size={10}>
          <ImagePreview src={raylist.imageCardUrl} alt={caption} />
          <Link
            to={{
              pathname: `/raylists/${raylist.id}/details`,
              state: { raylist },
            }}
          >
            {caption}
          </Link>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description: string) => (
        <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }}>
          {description}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Curator",
      dataIndex: "curator",
      width: "240px",
      render: (curator: PersonType) => <PersonTableCell person={curator} />,
    },
    {
      title: "Categories",
      dataIndex: "categories",
      width: "20%",
      render: (categories: CategoryType[]) => (
        <div>
          {categories.map((category) => (
            <Tag key={category.id} style={{ margin: "2px 4px" }}>
              {category.name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "",
      width: "1%",
      key: "actions",
      render: (_: any, raylist: RaylistType) => actions(raylist),
    },
  ];

  return (
    <RecordsProvider<RaylistType> columns={columns} {...otherProps}>
      <RecordsTableHeader {...tableHeaderProps} />
      <RecordsTable />
    </RecordsProvider>
  );
}

import { useRecordLoader } from "../../components/useRecordLoader";
import { ArchetypeType } from "../../types";
import { useSession } from "../../providers/SessionProvider";
import { useCallback } from "react";
import { PageLoader } from "../../components/PageLoader";

export function useArchetypeLoader() {
  const { http } = useSession();

  const fetchArchetypeRecord = useCallback(
    async (id: string) => {
      const resp = await http.get(`/archetypes/${id}`);
      return resp.data as ArchetypeType;
    },
    [http]
  );

  return useRecordLoader<ArchetypeType>({
    idParam: "archetypeId",
    recordStateProp: "archetype",
    fetchRecord: fetchArchetypeRecord,
  });
}

export function ArchetypePageLoader(props: any) {
  return <PageLoader useRecordLoader={useArchetypeLoader} {...props} />;
}

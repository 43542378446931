import React from "react";
import { Form, FormProps, Input } from "antd";
import { UploadS3File } from "../../components/Form/UploadS3File";
import { RaylistSelectRecord } from "../ray-list/RaylistSelectRecord";

interface ArchetypeFormProps extends FormProps {
  children?: React.ReactNode;
}

export function ArchetypeForm(props: ArchetypeFormProps) {
  const { children, ...formProps } = props;

  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item name="title" label="Title" rules={[{ required: true }]}>
        <Input style={{ maxWidth: "420px" }} />
      </Form.Item>

      <Form.Item name="feature" label="Featured Raylist app label">
        <Input style={{ maxWidth: "420px" }} />
      </Form.Item>

      <Form.Item name="defaultRayListId" label="Infinite RayList">
        <RaylistSelectRecord style={{ maxWidth: "420px" }} />
      </Form.Item>

      <Form.Item
        name="backgroundImageUrl"
        label="Background Image"
        rules={[{ required: true }]}
      >
        <UploadS3File fileType="ARCHETYPE_BACKGROUND_IMAGE" type="picture" />
      </Form.Item>

      <Form.Item
        name="backgroundVideoUrl"
        label="Background Video"
        rules={[{ required: true }]}
      >
        <UploadS3File fileType="ARCHETYPE_BACKGROUND_VIDEO" type="file" />
      </Form.Item>

      {children}
    </Form>
  );
}
